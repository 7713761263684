var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$tc("general"))+" ")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xs-8"},[_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{attrs:{"for":""}},[_vm._v(" "+_vm._s(_vm.$t("default_interval"))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.default_interval')}})],1),_c('div',{staticClass:"input-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.interval),expression:"interval"}],staticClass:"form-control text-center",staticStyle:{"z-index":"auto"},attrs:{"type":"number","inc":"1","min":"1","max":_vm.maxInterval},domProps:{"value":(_vm.interval)},on:{"input":function($event){if($event.target.composing)return;_vm.interval=$event.target.value}}}),_c('div',{staticClass:"input-group-addon"},[_vm._v(" "+_vm._s(_vm.$tc("hour", _vm.interval > 1 ? 2 : 1))+" ")])])]),_c('div',{staticClass:"form-group form-group-sm"},[_c('label',{staticClass:"input-checkbox clicable",staticStyle:{"z-index":"999"},attrs:{"for":"startupUpdate"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.startupUpdate = !_vm.startupUpdate}}},[_c('i',{class:_vm.startupUpdate
                                    ? 'fa fa-check-square-o'
                                    : 'fa fa-square-o',attrs:{"id":"startupUpdate"}}),_vm._v(" "+_vm._s(_vm.$t("update_at_startup"))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.update_at_startup')}})],1)])])])]),_c('TogglePanel',{staticStyle:{"margin-top":"-10px","padding":"0 10px"},attrs:{"title":"toolbar","persistent":"toggle_toolbar_items"}},[_c('div',{staticClass:"inner-panel"},[(_vm.hasTab)?_c('div',[_c('label',{staticClass:"input-checkbox clicable",attrs:{"for":"toolbarAlwaysVisible"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.toolbarAlwaysVisible = !_vm.toolbarAlwaysVisible}}},[_c('i',{class:_vm.toolbarAlwaysVisible
                                ? 'fa fa-check-square-o'
                                : 'fa fa-square-o'}),_vm._v(" "+_vm._s(_vm.$t("always_visible"))+" ")])]):_vm._e(),_vm._l((_vm.toolbarGroupList),function(group){return _c('div',{key:group},[(group)?_c('div',{staticClass:"toggle-title clicable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleToolbarGroup(group)}}},[_c('div',[_vm._v(" "+_vm._s(_vm.$t(`titles.${group}`))+" ")]),_c('span',{staticClass:"btn btn-xs"},[_c('i',{class:_vm.isToolbarGroupVisible(group)
                                    ? 'fa fa-caret-square-o-up'
                                    : 'fa fa-caret-square-o-down'})])]):_vm._e(),(_vm.isToolbarGroupVisible(group))?_c('div',{staticClass:"toolbar-group-container"},_vm._l((_vm.toolbarFilterByGroup(group)),function(item){return _c('div',{key:item.id,class:{
                            'bg-primary': item.id == _vm.toolbarDefaultTab
                        }},[_c('label',{staticClass:"input-checkbox clicable",style:({
                                color: !item.enabled ? '#888' : 'inherit'
                            }),attrs:{"for":item.id},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.toggleToolbarItem(item.id)}}},[_c('i',{class:item.enabled
                                        ? 'fa fa-check-square-o'
                                        : 'fa fa-square-o',staticStyle:{"min-width":"14px","font-size":"90%"}}),_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.title))+" ")]),(item.hint)?_c('tooltip',{attrs:{"title":_vm.$t(item.hint)}}):_vm._e()],1),(
                                group == 'tabs' &&
                                item.id != _vm.toolbarDefaultTab
                            )?_c('div',{staticClass:"clicable tab-selector"},[_c('span',{staticClass:"clicable-orange",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.toolbarDefaultTab = item.id}}},[_c('i',{staticClass:"fa fa-arrow-left"}),_vm._v(" "+_vm._s(_vm.$t("set_as_default"))+" ")])]):_vm._e(),(item.id == 'preview' && item.enabled)?[_c('div',{staticClass:"print-preview-panel",on:{"click":_vm.togglePageSettings}},[_c('div',[_c('div',{staticClass:"title clicable"},[_vm._v(" "+_vm._s(_vm.$t("page_settings"))+" ")]),_c('PageSettingsToolbar',{ref:"pageSettingsToolbar",refInFor:true,staticClass:"pdf-tool-bar",model:{value:(_vm.pageSettings),callback:function ($$v) {_vm.pageSettings=$$v},expression:"pageSettings"}})],1)])]:_vm._e()],2)}),0):_vm._e()])})],2)]),(_vm.hasDataFilter)?_c('TogglePanel',{staticStyle:{"margin-top":"10px","padding":"0 10px"},attrs:{"title":_vm.$tc('titles.data_filter', 1),"persistent":"toggle_toolbar_panel_data_filter","icon":{ before: 'fa fa-filter' }}},[_c('div',[_c('ControlDataSelector',{attrs:{"connectorId":(_vm.dataFilterData && _vm.dataFilterData.clp_id) || null,"parser":_vm.dataListParser,"label":_vm.$tc('data', 1),"allowedTypes":['bool', 'float', 'int', 'string']},model:{value:(_vm.dataFilterDataId),callback:function ($$v) {_vm.dataFilterDataId=$$v},expression:"dataFilterDataId"}}),(_vm.dataFilterDataId)?_c('div',{staticClass:"form-group form-group-sm"},[_c('div',{staticStyle:{"margin-bottom":"10px","position":"relative","z-index":"101"}},[_c('label',{staticClass:"clicable",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();_vm.dataFilterIsRequired = !_vm.dataFilterIsRequired}}},[_c('i',{class:_vm.dataFilterIsRequired
                                    ? 'fa fa-check-square-o'
                                    : 'fa fa-square-o'}),_vm._v(" "+_vm._s(_vm.$tc("required", 1))+" "),_c('Tooltip',{attrs:{"title":_vm.$t('hints.filter_required')}})],1)]),_c('div',[_c('span',[_vm._v(" "+_vm._s(_vm.$tc("label", 1))+" / "+_vm._s(_vm.$tc("value", 1))+" ")])]),_c('div',{staticStyle:{"clear":"both"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataFilterLabel),expression:"dataFilterLabel"}],staticClass:"form-control",staticStyle:{"width":"60%","display":"inline-block"},attrs:{"type":"text","placeholder":_vm.$t('label')},domProps:{"value":(_vm.dataFilterLabel)},on:{"input":function($event){if($event.target.composing)return;_vm.dataFilterLabel=$event.target.value}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dataFilterValue),expression:"dataFilterValue"}],staticClass:"form-control",staticStyle:{"width":"40%","display":"inline-block"},attrs:{"type":"text","placeholder":_vm.$t('default_value')},domProps:{"value":(_vm.dataFilterValue)},on:{"input":function($event){if($event.target.composing)return;_vm.dataFilterValue=$event.target.value}}})])]):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }