<template>
  <span @click.prevent="onClick()" :title="$tc('export')">
    <slot>
      <span class="btn btn-xs">
        <i class="fa fa-download"></i>
      </span>
    </slot>
  </span>
</template>

<script>
import DashboardPublisher from "@/components/editor/dashboard-publisher.vue";
export default {
  name: "DashboardDownloadBtn",
  extends: DashboardPublisher,
  props: {
    screenId: {
      type: [Number, String],
      required: true,
      default: () => 0
    },
    auto: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data() {
    return {
      screen: null
    };
  },
  watch: {
    screenId: {
      handler(n) {
        if (this.auto && n) {
          if (this.$store.getters["dashboard/screen"](n)) {
            this.onClick();
          }
        }
      },
      immediate: true
    }
  },
  methods: {
    async onClick() {
      this.screen =
        this.$store.getters["dashboard/screen"](this.screenId) || {};
      let content = await this.content();
      if (!content) return;
      content.origin = this?.screen?.path || "";
      this.$utils.download(
        JSON.stringify(content, null, "\t"),
        "application/json",
        this.screenName() + ".json"
      );
    },
    screenName() {
      let name = this.screen.name;
      if (this.screen.id < 0) {
        name = this.$t("new_screen");
        name = `${name} (${
          -1 * this.screen.id <= 9
            ? "0" + -1 * this.screen.id
            : -1 * this.screen.id
        })`;
      }
      return name;
    }
  }
};
</script>

<style scoped></style>
