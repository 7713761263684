<template>
  <section>
    <form role="form">
      <div class="input-group">
        <div class="input-group-addon btn" v-if="customizable">
          <input
            type="checkbox"
            v-model="all"
            v-bind:style="{
              visibility: !entry.items.length ? 'hidden' : 'initial'
            }"
          />
        </div>
        <div class="input-group-addon value-addon">
          <div class="value-input">
            <strong>{{ $tc(keyColumn) }}</strong>
          </div>
        </div>
        <div class="form-control text-center">
          <div class="value-input">
            <strong>{{ $t(valueColumn) }}</strong>
          </div>
        </div>
        <div
          class="trash-button"
          v-if="checked_list.length"
          v-on:click.stop.prevent="del"
          :title="$t('remove_selected_ones')"
        >
          <i class="fa fa-trash toolbar-button"></i>
        </div>
      </div>

      <div
        class="input-group"
        v-for="(item, ix) in entry.items"
        v-bind:key="ix"
      >
        <div class="input-group-addon" v-if="customizable">
          <input type="checkbox" v-model="item.checked" />
        </div>
        <div class="input-group-addon value-addon">
          <input
            class="value-input"
            v-model="item.id"
            v-bind:class="{ 'input-error': inError(ix) }"
          />
        </div>
        <input
          type="text"
          class="form-control no-outline text-center no-padding"
          v-model="item.text"
          v-bind:class="{ 'input-error': inError(ix) }"
        />
        <div
          class="input-group-addon btn"
          v-on:click.stop.prevent="entry.default_id = item.id"
          v-if="defaultSelection"
        >
          <i
            class="fa fa-check icon-default"
            v-bind:class="{
              'text-success': entry.default_id == item.id,
              'text-gray': entry.default_id != item.id
            }"
            v-bind:title="$t('set_as_default')"
          >
          </i>
        </div>
      </div>

      <div class="input-group new-item" v-if="customizable">
        <div
          class="input-group-addon btn fa-btn"
          v-bind:disabled="!isNewItemValid"
          v-on:click.prevent.stop="add"
        >
          <i class="fa fa-plus" style="margin:0 1px;"></i>
        </div>
        <div class="input-group-addon value-addon">
          <input
            class="value-input"
            type="text"
            v-model="new_item.id"
            ref="new_id"
            v-bind:placeholder="$t(keyText)"
            @keydown.enter.prevent.stop="add"
            @blur="onLostFocus"
          />
        </div>
        <input
          class="form-control text-center no-outline no-padding"
          type="text"
          v-model="new_item.text"
          v-bind:placeholder="$t(valueText)"
          @keydown.enter.prevent.stop="add"
          @keydown.tab.exact.prevent.stop="add"
          @blur="onLostFocus"
        />
      </div>
    </form>
  </section>
</template>

<script>
function defaultItem() {
  return { id: "", text: "", checked: false, error: false };
}

export default {
  name: "FormKeyValue",
  props: {
    value: {
      type: Object,
      default: () => null
    },
    defaultSelection: {
      type: Boolean,
      default: () => true
    },
    keyColumn: {
      type: String,
      default: () => "value",
      required: false
    },
    valueColumn: {
      type: String,
      default: () => "text",
      required: false
    },
    keyText: {
      type: String,
      default: () => "value",
      required: false
    },
    valueText: {
      type: String,
      default: () => "new_text_value",
      required: false
    },
    noFocus: {
      type: Boolean,
      default: () => false,
      required: false
    },
    customizable: {
      type: Boolean,
      default: () => true,
      required: false
    }
  },
  data() {
    return {
      busy: false,
      entry: null,
      all: false,
      new_item: defaultItem(),
      etag: "",
      id: "",
      pending: false
    };
  },
  computed: {
    isValid() {
      return (this.entry.name && this.entry.items.length) || false;
    },
    isNewItemValid() {
      return this.new_item.id && this.new_item.text;
    },
    checked_list() {
      return (this.entry.items || []).filter(function(i) {
        return i.checked;
      });
    },
    errors() {
      let ids = this.entry.items.map(function(i) {
        return i.id + "";
      });
      let inserted = {};
      let errors = {};
      let items = this.entry.items.concat([this.new_item]);
      for (var i in items) {
        var key = items[i].id + "";
        if (key in inserted) {
          errors[i] = inserted[key];
        } else {
          inserted[key] = i;
        }
      }
      return errors;
    },
    formData() {
      let self = this;
      let payload = null;
      if (this.entry) {
        if ((this?.entry?.items || []).length) {
          payload = JSON.parse(JSON.stringify(this.value)) || {}; // original
          let default_item = null;
          for (let i in this.entry.items) {
            if (this.entry.items[i].id == this.entry.default_id) {
              default_item = {};
              default_item[this.entry.items[i].id] = this.entry.items[i].text;
            }
          }
          payload.items = JSON.parse(JSON.stringify(this.entry.items));
        }
      }
      return payload;
    }
  },
  watch: {
    all(n) {
      let items = JSON.parse(JSON.stringify(this.entry.items || [])).map(
        function(i) {
          i.checked = n;
          return i;
        }
      );
      this.$set(this.entry, "items", items);
    },
    formData(n, o) {
      if (JSON.stringify(n) != JSON.stringify(o)) {
        this.$emit("input", n);
        this.pending = false;
      }
    }
  },
  methods: {
    inError(position) {
      let errors = this.errors;
      for (var i in errors) {
        if (errors[i] == position) {
          return true;
        }
      }
      return false;
    },
    add() {
      this.pending = false;
      if (!this.isNewItemValid) return;
      this.entry.items.push({
        id: this.new_item.id,
        text: this.new_item.text,
        checked: false
      });
      if (this.entry.items.length == 1) {
        this.entry.default_id = this.new_item.id;
      }
      this.new_item = defaultItem();
      this.focus();
    },
    del() {
      let items = JSON.parse(JSON.stringify(this.entry.items || [])).filter(
        (i) => !i.checked
      );
      this.$set(this.entry, "items", items);
      if (!items.length) {
        this.all = false;
      }
      let default_lst = items.filter((i) => i.id == this.entry.default_id);
      if (!default_lst.length && items.length) {
        this.entry.default_id = items[0].id;
      } else {
        this.entry.default_id = "";
      }
      this.focus();
    },
    focus() {
      this.$nextTick(() => {
        if (this.entry && !this.entry.name) {
          if (this?.$refs?.entry_name) this.$refs.entry_name.focus();
        } else {
          if (this?.$refs?.new_id) this.$refs.new_id.focus();
        }
      });
    },
    initData() {
      let entry = null;
      if (this.value) {
        entry = JSON.parse(JSON.stringify(this.value));
        for (var i in entry.items || []) {
          entry.items[i].checked = false;
        }
      } else {
        entry = {
          default_id: 0,
          id: 0,
          name: "",
          items: []
        };
      }
      this.$set(this, "new_item", defaultItem());
      this.$set(this, "entry", entry);
    },
    onLostFocus() {
      this.pending = this.new_item.id && this.new_item.text ? true : false;
    }
  },
  created() {
    this.initData();
  },
  mounted() {
    if (!this.noFocus) this.focus();
  }
};
</script>

<style scoped>
.padding-1 {
  padding: 1px;
}
.toolbar-button {
  margin: 0 5px;
}
.value-addon {
  padding: 0;
  width: 100px;
}
.fa-btn:hover {
  color: #3c8dbc;
  cursor: pointer;
}
.value-addon-disabled {
  background-color: whitesmoke;
}
.input-group {
  width: 100%;
}
.value-input {
  width: 90%;
  padding: 0;
  border: 0;
  outline-width: 0;
  text-align: center;
}
.value-input:focus {
  border: 0;
  outline-width: 0;
}
.icon-edit {
  color: darkgrey;
  padding-left: 1px;
}
.input-error {
  color: tomato;
}
.btn-gap {
  margin-right: 5px;
}
.icon-default {
  margin: 0 -1px 0 -2px;
}
.trash-button {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 9;
  border-radius: 3px;
  padding: 0;
  font-size: 1em;
}

.trash-button:hover {
  opacity: 0.7;
  color: brown;
  cursor: pointer;
}

.no-outline:focus {
  outline: none !important;
  border-color: #d2d6de !important;
}

.new-item input::placeholder {
  font-size: 1.2rem;
}
</style>
