<template>
  <div v-if="inline" class="form-inline">
    <div class="form-group form-group-sm has-feedback">
      <label for="reference-connector">{{
        $t("synoptic.reference_connector")
      }}</label>

      <v-select
        @input="setSelected"
        @search:focus="$emit('focus')"
        :value="(isEnabled && selected) || ''"
        :options="(isEnabled && options) || []"
        :reduce="(option) => option.id"
        label="name"
        :disabled="!isEnabled || loading"
        class="vselector"
        data-testid="ref-map"
      />
      <span
        v-if="loading"
        class="fa fa-refresh fa-spin form-control-feedback"
        aria-hidden="true"
        data-testid="loading"
      ></span>
      <span
        v-if="error"
        class="fa fa-warning form-control-feedback text-danger"
        aria-hidden="true"
        data-testid="error"
      ></span>
      <span
        v-if="isEnabled && hasChanges && !error"
        class="fa fa-circle form-control-feedback"
        style="color: #ecc816; font-size: 1rem"
        aria-hidden="true"
        data-testid="changes"
      ></span>
    </div>
    <label
      style="margin-left: 10px; vertical-align: bottom"
      v-if="hasBasedModelConnectors"
    >
      <input type="checkbox" v-model="all" />
      <span style="margin-left: 5px">{{ $tc("models", 1) }}</span>
    </label>
  </div>
  <div v-else>
    <div class="form-group form-group-sm has-feedback">
      <label
        for="reference-connector"
        class="no-select"
        style="display: block; position: relative; margin-right: 0"
      >
        {{ $t("synoptic.reference_connector") }}
        <Tooltip :title="$t('hints.reference_connector')" />
        <div
          class="model-only-check no-select"
          :class="{ active: !all }"
          v-if="hasBasedModelConnectors"
          @click.stop.prevent="all = !all"
          :title="$t('hints.does_it_show_models_only_or_all_connectors')"
        >
          {{ $tc("models", 1) }}
          <i :class="all ? 'fa fa-square-o' : 'fa fa-check-square-o'"></i>
        </div>
      </label>
      <v-select
        @input="setSelected"
        @search:focus="$emit('focus')"
        :value="(isEnabled && selected) || ''"
        :options="(isEnabled && options) || []"
        :reduce="(option) => option.id"
        label="name"
        :disabled="!isEnabled || loading"
        data-testid="ref-map"
      />
      <span
        v-if="loading"
        class="fa fa-refresh fa-spin form-control-feedback"
        aria-hidden="true"
        data-testid="loading"
      ></span>
      <span
        v-if="error"
        class="fa fa-warning form-control-feedback text-danger"
        aria-hidden="true"
        data-testid="error"
      ></span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Tooltip from "@/components/tooltip.vue";
import isEqual from "lodash/isEqual";
export default {
  name: "ReferenceConnectorSelector",
  components: {
    Tooltip
  },
  props: {
    refMap: {
      type: Object,
      required: false
    },
    screenId: {
      type: Number,
      required: true
    },
    inline: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      ref_map: {},
      error: false,
      hasChanges: false,
      selected: null,
      all: true
    };
  },
  computed: {
    isEnabled() {
      if (this?.screen) {
        if (!this?.screen?.public) {
          return true;
        }
      }
      return false;
    },
    screen() {
      return this.screenId != null
        ? this.$store.getters["dashboard/screen"](this.screenId)
        : null;
    },
    hasBasedModelConnectors() {
      return (this.connectorList || []).findIndex((i) => i.base_model_id) >= 0;
    },
    options() {
      let lst = this.connectorList || [];
      if (!this.all && this.hasBasedModelConnectors) {
        lst = lst.filter((i) => (i?.base_model));
      }
      return lst || [];
      // return (lst).map(i=>({
      //   label: i.name,
      //   text: `#${i.id} ${i.name}`,
      //   item: i.id
      // }))
    },
    title() {
      if (this.error) return this.$t("failed_to_update_ref_map");
      else if (this.loading) return this.$t("updating") + "...";
      else if (!this.hasPermission)
        return this.$t(
          "you_do_not_have_enough_privileges_to_execute_this_action"
        );
      else if (this.hasChanges) return this.$t("unsaved_changes");
      else return this.$t("select_ref_connector");
    },
    hasPermission() {
      //return (this.isEnabled && this.$can("manage", "TelaCustomizadaEscrita"));
      return true; // valiparent component
    },
    loading() {
      return this.$store.getters?.isLoading || false;
    },
    // ...mapState({ connectorList: (state) => state.equipmentList }),
    ...mapGetters("dashboard", ["screenRefMap", "connectorList"])
  },
  methods: {
    fetchEquipmentList() {
      if (this.connectorList?.length) {
        return;
      } else {
        this.$store.dispatch("fetchEquipmentList");
      }
    },
    setSelected(value) {
      this.selected = value ? value : null;
      if (this.selected) {
        //if (!this.hasPermission) return;
        this.ref_map = { conn1: parseInt(this.selected) };
        // compares selected ref map to screen current one
        if (!isEqual(this.ref_map, this.refMap)) {
          this.hasChanges = true;
        } else {
          this.hasChanges = false;
        }
      } else {
        this.ref_map = {};
        this.hasChanges = false;
      }
      let payload = {
        screenId: this.screenId,
        ref_map: { ...this.ref_map }
      };
      this.updateRefMap(payload);
      this.$emit("updateScreenRefMap", payload);
    },
    restoreFromPrevious() {
      // it makes it compatible with previous version (without reference_connectors)
      let screen = this.$store.getters["dashboard/screen"](this.screenId);
      let template = this.$store.getters["dashboard/template"](this.screenId);
      if (
        !(screen.reference_connectors || []).length &&
        template &&
        template?.ref_map?.conn1
      ) {
        this.setSelected(template?.ref_map?.conn1);
      }
    },
    ...mapActions("dashboard", ["updateRefMap"])
  },
  watch: {
    connectorList(n) {
      if (n && n.length && this.screenId) {
        if (!this.screenRefMap(this.screenId)) {
          this.restoreFromPrevious();
        }
      }
    },
    refMap: {
      immediate: true,
      handler(val) {
        if (this.screenRefMap(this.screenId)) {
          this.ref_map = { ...this.screenRefMap(this.screenId) };
          this.selected = this.ref_map?.conn1 || null;
          if (!isEqual(this.screenRefMap(this.screenId), val)) {
            this.hasChanges = true;
          } else {
            this.hasChanges = false;
          }
        } else if (val?.conn1) {
          this.setSelected(val.conn1);
        } else {
          this.selected = null;
          this.ref_map = {};
          this.hasChanges = false;
          if ((this?.connectorList || [])?.length) {
            this.restoreFromPrevious();
          } else {
            this.fetchEquipmentList();
          }
        }
      }
    }
  },
  created() {
    this.fetchEquipmentList();
  }
};
</script>

<style scoped>
.form-inline {
  display: inline-block;
  margin-right: 1rem;
}

label {
  margin-right: 1rem;
}

.vselector {
  display: inline-block;
  min-width: 260px;
}

v-select {
  font-size: 80%;
}

.form-control-feedback {
  margin-right: 35px;
  margin-top: -2px;
}
.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.model-only-check {
  position: absolute;
  right: -3px;
  top: 4px;
  font-size: 80%;
  color: #888;
}

.model-only-check:hover {
  cursor: pointer;
  color: #5e82a2;
}

.model-only-check.active {
  color: #5e82a2;
}
.model-only-check > i {
  vertical-align: middle;
  min-width: 16px;
}
</style>
