<template>
  <div>
    <slot name="toggle">
      <span
        class="btn btn-default"
        :class="floatPanel.open ? 'active' : ''"
        @click.stop.prevent="toggle"
      >
        {{ $t($attrs.title) }}
      </span>
    </slot>
    <portal to="contextmenu">
      <FloatPanel
        class="float-panel float-panel-data-simulator"
        :draggable="true"
        :handleSelf="true"
        :defaultPosition="{top: floatPanel.top, left: floatPanel.left}"
        :open="floatPanel.open"
        @update:open="onFloatPanelUpdate($event)"
        @update:defaultPosition="onFloatPanelPosition($event)"
        @dragstart="floatPanel.dragging = true"
        @dragend="onDragEnd(floatPanel, $event)"
      >
        <div class="popup" v-if="floatPanel.open">
          <div class="header" :title="$t($attrs.title)">
            {{ $t($attrs.title) }}
          </div>
          <div class="popup-body">
            <slot></slot>
          </div>
        </div>
      </FloatPanel>
    </portal>
  </div>
</template>

<script>
import FloatPanel from "@/components/editor/float-panel.vue";
const _fpsize = [500, 200];
let _fp = {
  h: _fpsize[1],
  w: _fpsize[0],
  y: parseInt((window.innerHeight - _fpsize[1]) / 2) - 150,
  x: parseInt((window.innerWidth - _fpsize[0]) / 2)
};
_fp.y = _fp.y < window.innerHeight ? _fp.y : parseInt(window.innerHeight * 0.8);
_fp.x = _fp.x < window.innerWidth ? _fp.x : parseInt(window.innerWidth * 0.8);

export default {
  name: "FloatPanelToggle",
  components: {
    FloatPanel
  },
  data() {
    return {
      busy: false,
      floatPanel: {
        top: `${_fp.y}px`,
        left: `${_fp.x}px`,
        open: false,
        dragging: false
      }
    };
  },
  methods: {
    onFloatPanelPosition($event) {
      if (this.floatPanel.open) {
        this.busy = false;
        let $el = document.getElementsByClassName("float-panel-data-simulator");
        let h = $el && $el.length == 1 ? $el[0].clientHeight : _fp.h;
        let top = parseInt($event.top.replace(/\D+/, "") || 0);
        if (top <= document.documentElement.scrollTop) {
          top = h
            ? h / 2 + document.documentElement.scrollTop
            : document.documentElement.scrollTop;
        }
        this.floatPanel.top = `${top}px`;
        if (top + h >= window.innerHeight) {
          window.scrollTo(undefined, top);
        }
        // console.log(top);
      }
    },
    onFloatPanelUpdate(value) {
      if (this.floatPanel.open != value) {
        this.floatPanel.open = value;
      }
    },
    toggle() {
      this.floatPanel.open = !this.floatPanel.open;
      if (this.floatPanel.open) {
        // this.busy = true;
      }
    },
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    }
  }
};
</script>

<style scoped>
.float-panel {
  padding: 30px 0 0 0;
  overflow: hidden;
}

.float-panel:hover {
  cursor: move;
}

.popup {
  position: relative;
}

.popup > .header {
  position: absolute;
  top: -29px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 16pt;
  font-weight: 600;
  padding: 0px 5px;
  color: #666;
  text-shadow: 0px 1px lightslategrey;
  pointer-events: none;
}

.popup > .popup-body {
  width: 500px;
  min-width: 500px;
  min-height: 200px;
  max-height: 60vh;
  resize: both;
  overflow: auto;
  border-top: 1px solid lightgray;
  display: flex;
  align-items: stretch;
  padding: 5px;
}

.popup > .popup-body:hover {
  cursor: default;
}
</style>