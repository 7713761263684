<template>
  <section>
    <div class="alert bg-warning fade show in">
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-label="Close"
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <strong>{{ $t("attention") }}!</strong>
      <p>
        {{ $t("configured_screen_file_not_found") }} {{ $t("or") }}
        {{ $t("you_do_not_have_access_to_the_requested_resource") }}.
      </p>
    </div>
  </section>
</template>

<script>
export default {
  name: "DashboardNotFound"
};
</script>

<style scoped>
section {
  margin: 20px;
  box-shadow: 6px 6px 7px grey;
}
</style>
