<template>
  <div
    class="dashboard-inner no-select"
    ref="editor"
    :class="{resizing: resizing}"
    @drop="handleDrop($event)"
  >
    <div
      class="row"
      v-for="(row, ixRow) in grid"
      :class="targetRow == ixRow && dragging == 'layout' ? `dragging` : ''"
      :key="ixRow"
      @dragover="targetRow = ixRow"
      @dragleave="targetRow = ''"
    >
      <div class="drop-zone"></div>
      <div
        class="col-xs-12"
        v-for="(col, ixCol) in row"
        v-bind:key="ixCol"
        :class="col.column_class"
        @drop="handleDrop($event, ixRow, ixCol)"
      >
        <div
          v-for="(panelName, ixPanel) in col.panels"
          v-bind:key="ixPanel"
          class="painel-container"
          :class="{
            disabled:
              (!currentDraftPanel || currentDraftPanel.name != panelName) &&
              !resizing,
            dragging: targetPanel == panelName && dragging == 'panel'
          }"
          @drop="handleDrop($event, ixRow, ixCol, ixPanel)"
          @click.prevent="onPanelContentClicked(panelName)"
          @dragover="targetPanel = panelName"
          @dragleave="targetPanel = ''"
        >
          <div
            class="panel-handler"
            v-if="
              (!currentDraftPanel || currentDraftPanel.name != panelName) &&
              !resizing
            "
            @dragstart="
              jsonDragger.onDragStart($event, 'panelsSwap', panelName)
            "
            @dragend="jsonDragger.onDragEnd($event)"
            @dragover="jsonDragger.onDragOver($event)"
            draggable="true"
            :title="`${$t('click_to_edit')} ${$t('or')} ${$t('drag_to_move')}`"
          >
            <div class="container-info" v-if="panelInfo[panelName]">
              <div class="text-center">
                <div>
                  <img
                    :src="panelInfo[panelName].thumbnail"
                    style="width: 80px; margin-top: 10px"
                  />
                </div>
                <div style="font-size: 1.6em">
                  {{ panelInfo[panelName].title }}
                </div>
              </div>
            </div>
            <div class="drag-info">
              <div>
                <div>
                  <i class="fa fa-pencil"></i> {{ $t("click_to_edit") }}
                </div>
                <div>
                  <i class="fa fa-arrows-alt"></i> {{ $t("drag_to_move") }}
                </div>
              </div>
            </div>
          </div>
          <PanelEditorToolbar :panelName="panelName" @click="onPanelToolbar" />
          <EquipmentDashboardPanel
            :equipment="equipment"
            :display="display"
            :name="panelName"
            :screenId="screenId"
            @mounted="panelMounted"
          />
          <div
            v-if="ixCol < row.length - 1"
            class="panel-resize-handler"
            :class="{resizing: resizing}"
            @mousedown="handleMousedown($event, ixRow, ixCol)"
            :style="{
              right:
                resizing && resizing.r == ixRow && resizing.c == ixCol
                  ? resizing.pos
                  : '0'
            }"
          >
            <div><div /></div>
          </div>
        </div>
      </div>
      <div
        v-if="!resizing"
        class="layout-toolbar"
        :title="$t('remove_layout')"
        @drop="handleDrop($event, ixRow)"
      >
        <div>
          <span
            class="btn btn-xs"
            style="margin-right: 15px"
            v-if="ixRow > 0"
            :class="isPageBrakeEnabledAt(ixRow) ? 'btn-active' : ''"
            @click.stop.prevent="enablePageBrakeAt(ixRow)"
          >
            {{ $t("page_brake") }} <i class="fa fa-eject"></i>
          </span>

          <span
            class="btn btn-xs"
            @click.stop.prevent="addColumn(ixRow)"
            v-if="canAddColumn(ixRow)"
          >
            {{ $t("rich_text.add_column") }}
            <i class="fa fa-columns"></i>
          </span>
          <span
            class="btn btn-xs"
            @click.stop.prevent="removeRow($event, ixRow)"
          >
            {{ $t("remove_layout") }}
            <i class="fa fa-trash"></i>
          </span>
        </div>
        <section
          v-if="!resizing && ixRow > 0 && isPageBrakeEnabledAt(ixRow)"
          class="page-break-indicator"
          :title="$t('page_brake')"
        >
          <i class="fa fa-eject"></i>
        </section>
      </div>
    </div>
    <div
      class="row"
      :class="targetRow == -1 && dragging == 'layout' ? `dragging` : ''"
      @dragover="targetRow = -1"
      @dragleave="targetRow = ''"
    >
      <div class="drop-zone"></div>
      <div
        class="col-xs-12"
        :style="dragging == 'layout' ? 'height: 100dvh' : ''"
      ></div>
    </div>
  </div>
</template>
<script>
/*
this component displays panels based on configuration
 gridLayout is a composed array: 
   rows [ columns [  [panels] ] ]
  
   - column width are always in % - but it is not required
   - panels within same column are stacked 
*/
import DashboardContentBase from "@/equipment-dashboard-templates/dashboard-content-base.vue";
import PanelEditorToolbar from "@/components/editor/panel-editor-toolbar.vue";
import {BaseLayoutConfig} from "@/services/dashboard.js";
import messages from "@/i18n/dashboard-panels.js";
const panelBottomMargin = 10;
const maxColumns = 4;
// panel property forms
export default {
  name: "DashboardContentEditor",
  extends: DashboardContentBase,
  components: {
    PanelEditorToolbar
  },
  data() {
    return {
      resizing: false,
      expandedPanel: "",
      fullscreenPanel: "",
      panelInfo: {},
      targetRow: "",
      targetPanel: ""
    };
  },
  computed: {
    display() {
      return this.$store.getters["dashboard/draft"]?.template || null;
    },
    layout() {
      var self = this;
      var role = self.$store.getters["user/loggedUserRole"] || null;
      if (role && "layout" in role) {
        return role.layout;
      } else {
        if (self.display && "layout" in self.display) {
          return self.display.layout;
        }
      }
      return null;
    },
    currentDraftPanel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    defaultPanel() {
      return this.$store.getters["dashboard/defaultPanelConfiguration"];
    },
    dragging() {
      return this.$store.getters["dashboard/dragging"];
    }
  },
  watch: {
    panels: {
      handler(n, o) {
        if (!n) return;
        this.buildPanelInfo(o ? 200 : 2000);
      },
      immediate: true
    }
  },
  methods: {
    originalPanel(panel) {
      return (this.$store.getters["dashboard/allPanels"] || []).find(
        (p) => p.template.template == panel.template
      );
    },
    confirm(what) {
      return this.$swal({
        title: this.$t("are_you_sure"),
        text: this.$t("you_wont_be_able_to_revert_this"),
        icon: "warning",
        buttons: [this.$t("cancel"), this.$t(what || "yes_delete_it")]
      });
    },
    onDropLayout(ir, ic, ip, layout) {
      // console.log(`${ir} ${ic} ${ip} layout`);
      this.$root.$emit("dashboard:editor", {
        action: "addLayout",
        data: {
          row: ir,
          layout: layout,
          panelBottomMargin: panelBottomMargin
        }
      });
      this.delayedResize();
    },
    onDropPanel(ir, ic, ip, panel, e) {
      // console.log(`${ir} ${ic} ${ip} panel`);
      if (!panel || ir === undefined || ic === undefined || ip === undefined)
        return;
      const _run = () => {
        let panelTemplate = null;
        let linkedScreen = null;
        if (panel.pasting) {
          let src = (this?.display?.panels || []).find(
            ({name}) => name == panel.pasting
          );
          if (!src) return;
          panelTemplate = JSON.parse(JSON.stringify(src));
          panelTemplate.name = this.$utils.uuid();
          if (panelTemplate.template == "SynopticPanel") {
            (panelTemplate?.options?.controls || []).forEach((ctrl) => {
              ctrl.id = this.$utils.uuid();
            });
          }
        } else {
          panelTemplate = panel.template;
          if (panel.linkedScreen) {
            // it does not change template name
            linkedScreen = panel.linkedScreen;
            delete panel.linkedScreen;
          } else if (!panel.singleton) {
            panelTemplate.name = this.$utils.uuid();
          }
        }
        this.$root.$emit("dashboard:editor", {
          action: "setPanelAt",
          data: {
            row: ir,
            col: ic,
            pos: ip,
            panel: panelTemplate,
            linkedScreen: linkedScreen
          }
        });
        this.$nextTick(this.delayedResize);
      };
      let prvPanelName = (this.display.layout[ir][ic] || {})?.panels[ip];
      let prvPanel = (this.display.panels || []).find(
        ({name}) => name === prvPanelName
      );
      if (
        !e.ctrlKey &&
        prvPanel &&
        prvPanel.template != "EquipmentEmptyPanel"
      ) {
        this.$utils
          .confirm(this, "you_wont_be_able_to_revert_this")
          .then((ok) => {
            if (!ok) return;
            _run();
          });
      } else {
        _run();
      }
    },
    onPanelsSwap(ir, ic, ip, panelName) {
      this.$root.$emit("dashboard:editor", {
        action: "swapPanels",
        data: {
          a: panelName,
          b: this.layout[ir][ic]?.panels[ip]
        }
      });
      this.$nextTick(() => {
        this.delayedResize();
      });
    },
    handleDrop(e, ir, ic, ip) {
      if (this.resizing) return;
      this.jsonDragger.onDrop(e, {
        layout: (e, data) => {
          this.onDropLayout(ir, ic, ip, data, e);
        },
        panel: (e, data) => {
          this.onDropPanel(ir, ic, ip, data, e);
        },
        panelsSwap: (e, data) => {
          this.onPanelsSwap(ir, ic, ip, data);
        }
      });
    },
    handleMousedown(e, r, c) {
      // this.$root.$emit("controlSidebar:setContent", null);
      this.$set(this, "resizing", {
        r: r,
        c: c,
        x: e.pageX,
        w: this.$refs.editor.clientWidth,
        pos: null
      });
      this.draggingEl = e.target;
      this.draggingEl.style.right = "15px";
    },
    handleMousemove(e) {
      if (!this.resizing || !this.draggingEl) return;
      this.resizing.pos = `${-1 * (e.pageX - this.resizing.x)}px`;
    },
    handleMouseup(e) {
      if (!this.resizing || !this.draggingEl) return;
      e.preventDefault();
      e.stopPropagation();
      this.draggingEl = null;
      const w = this.$refs.editor.clientWidth;
      const n = parseInt(12 * (e.pageX / w));
      const o = parseInt(12 * (this.resizing.x / this.resizing.w));
      if (n != o) {
        this.$root.$emit("dashboard:editor", {
          action: "setColWidth",
          data: {
            row: this.resizing.r,
            col: this.resizing.c,
            width: parseInt((e.pageX / w) * 100)
          }
        });
      }
      setTimeout(
        () => {
          this.resizing = null;
        },
        10,
        this
      );
    },
    async onPanelContentClicked(panelName) {
      if (this.resizing || this?.currentDraftPanel?.name == panelName) return;
      this.editGenericPanelProperties(panelName);
    },
    getDraftPanel(panelName) {
      return (
        this.$store.getters["dashboard/draftPanel"]({
          screenId: this.screenId,
          panelName: panelName
        }) || null
      );
    },
    goto(panelName) {
      const path = `/dashboard/screen/edit/${this.screenId}/${panelName}`;
      if (path != this.$route.path) {
        this.$router.push(path);
      }
    },
    canAddColumn(r) {
      return (this?.display?.layout || [])[r]?.length < maxColumns;
    },
    removeRow($event, r) {
      const _doIt = () => {
        this.$root.$emit("dashboard:editor", {
          action: "delRow",
          data: {row: r}
        });
        this.$root.$emit("dashboard:editor", {
          action: "editDashboard"
        });
        this.delayedResize();
        return true;
      };
      $event.ctrlKey ? _doIt() : this.confirm().then((resp) => resp && _doIt());
    },
    addColumn(r) {
      this.$root.$emit("dashboard:editor", {
        action: "addCol",
        data: {row: r}
      });
    },
    removePanel(panelName, ctrlKey) {
      const _removePanel = async (panelName, removeLayout) => {
        this.$root.$emit("dashboard:editor", {
          action: "delPanel",
          data: removeLayout
            ? {panelName: panelName}
            : {panelName: panelName, replaceBy: this.defaultPanel.template}
        });
        this.$root.$emit("dashboard:editor", {
          action: "editDashboard"
        });
        this.delayedResize();
      };
      // there is no need to confirm the removal of default panel
      if (
        (this.display.panels || []).find(({name}) => name == panelName)
          ?.template == this.defaultPanel.template
      ) {
        _removePanel(panelName, true);
        return;
      }
      // since it is not the default one, ask the user before proceed
      this.confirm().then((resp) => {
        if (!resp) return;
        _removePanel(panelName, ctrlKey);
      });
    },
    editGenericPanelProperties(panelName) {
      //console.log(panelName);
      this.$root.$emit("dashboard:editor", {
        action: "editPanel",
        data: {panelName: panelName, showContentProperties: false}
      });
    },
    editSpecificPanelProperties(panelName) {
      this.$root.$emit("dashboard:editor", {
        action: "editPanel",
        data: {panelName: panelName, showContentProperties: true}
      });
    },
    expandPanel(panelName) {
      if (this.expandedPanel && panelName == this.expandedPanel) {
        this.expandedPanel = "";
      } else {
        this.expandedPanel = panelName;
      }
      this.$store.dispatch("dashboard/expand", this.expandedPanel);
    },
    closePanel() {
      this.expandPanel();
      this.$root.$emit("dashboard:editor", {action: "editDashboard"});
      this.$nextTick(() => {
        this.$root.$emit("drawer:event", {
          action: "tab:activate",
          details: "components"
        });
      });
    },
    onPanelToolbar($event, payload) {
      if (
        payload.handler in this &&
        typeof this[payload.handler] == "function"
      ) {
        // if (payload.handler == "removePanel" && $event.ctrlKey) {
        //   this[payload.handler](payload.panelName, true);
        //   return;
        // }
        this[payload.handler](payload.panelName, $event.ctrlKey);
      }
    },
    isPageBrakeEnabledAt(ir) {
      let layoutConfig = this?.display?.layoutConfig || BaseLayoutConfig();
      return layoutConfig.pageBreak.indexOf(ir) >= 0;
    },
    enablePageBrakeAt(ir) {
      this.$root.$emit("dashboard:editor", {
        action: "enablePageBrakeAt",
        data: {row: ir}
      });
    },
    // END generic panel toolbar handlers:
    resize() {
      if (!this.display.render_version || !this.layout) return;
      for (var row in this.layout) {
        let rowHeight = this.onResizeReferenceColumn(row);
        if (!rowHeight) return;
        if (this.layout[row].length > 1) {
          for (var col = 1; col < this.layout[row].length; col++) {
            this.onResizeColumn(row, col, rowHeight);
          }
        }
      }
    },
    buildPanelInfo(ts) {
      if (this._infoTimer) return;
      this.$nextTick(() => {
        this._infoTimer = setInterval(
          () => {
            if (this.dirty || !this.isRendered());
            clearInterval(this._infoTimer);
            this._infoTimer = null;
            setTimeout(
              () => {
                this.$set(this, "panelInfo", {});
                let entry = {};
                let panel = null;
                (this.panels || []).forEach((panelName) => {
                  panel = this.display.panels.find(
                    ({name}) => name == panelName
                  );
                  panel = panel && this.originalPanel(panel);
                  entry[panelName] = panel
                    ? {
                        thumbnail: panel.thumbnail,
                        title: this.$t(panel.title)
                      }
                    : null;
                });
                this.$set(this, "panelInfo", entry);
              },
              ts || 1000,
              this
            );
          },
          200,
          this
        );
      });
    },
    pan() {
      // const section = document.querySelector('section');
      // const div = document.querySelector('div');
      // // Listen for mousedown event on the section element
      // section.addEventListener('mousedown', (event) => {
      //   // Check if the middle mouse button is pressed
      //   if (event.button === 1) {
      //     // Prevent the default behavior of the mouse down event
      //     event.preventDefault();
      //     // Add event listeners for mousemove and mouseup events
      //     document.addEventListener('mousemove', handleMouseMove);
      //     document.addEventListener('mouseup', handleMouseUp);
      //     // Initialize the scroll position
      //     let scrollX = div.scrollLeft;
      //     let scrollY = div.scrollTop;
      //     // Function to handle mousemove events
      //     function handleMouseMove(event) {
      //       // Calculate the difference between the current and previous mouse positions
      //       const deltaX = event.clientX - event.offsetX;
      //       const deltaY = event.clientY - event.offsetY;
      //       // Update the scroll position
      //       scrollX += deltaX;
      //       scrollY += deltaY;
      //       // Scroll the div element
      //       div.scrollLeft = scrollX;
      //       div.scrollTop = scrollY;
      //     }
      //     // Function to handle mouseup events
      //     function handleMouseUp() {
      //       // Remove event listeners for mousemove and mouseup events
      //       document.removeEventListener('mousemove', handleMouseMove);
      //       document.removeEventListener('mouseup', handleMouseUp);
      //     }
      //   }
      // });
    }
  },
  mounted() {
    this.$root.$on("dashboard:refresh", this.refresh);
    if (this.display.render_version) {
      this.resize();
      this.$root.$on("panel:resized", this.delayedResize);
      window.addEventListener(
        "resize",
        () => {
          this.$root.$emit("dashboard:editor", {action: "updateWidth"});
          this.delayedResize();
        },
        false
      );
    }
    // editor event handler
    document.addEventListener("mousemove", (e) => this.handleMousemove(e));
    document.addEventListener("mouseup", (e) => this.handleMouseup(e));
  },
  beforeDestroy() {
    this.$root.$off("dashboard:refresh", this.refresh);
    this.$root.$off("panel:resized", this.delayedResize);
    window.removeEventListener("resize", this.delayedResize, false);
    // editor event handler:
    document.removeEventListener("mousemove", this.handleMousemove);
    document.removeEventListener("mouseup", this.handleMouseup);
    delete this.jsonDragger;
  },
  created() {
    // make it configurable
    this.$store.dispatch("dashboard/togglePanel", [
      // dashboard form state: collapsed
      {name: "toggle_screen_general", state: true},
      {name: "toggle_screen_components", state: true},
      {name: "toggle_screen_maintenance", state: true},
      // initial state: collapsed
      {name: "synoptic_control_data_selection", state: true},
      {name: "synoptic_control_info", state: true},
      {name: "synoptic_control_permissions", state: true},
      {name: "synoptic_control_appearance", state: true},
      {name: "synoptic_control_text", state: true},
      {name: "synoptic_control_info", state: true},
      {name: "synoptic_control_specific_properties", state: true},
      {name: "toggle_dashboard_panels", state: true},
      // initial state: expanded
      {name: "toggle_synoptic_available_controls", state: false},
      {name: "toggle_synoptic_controls", state: false},
      {name: "toggle_available_layouts", state: false},
      {name: "toggle_available_panels", state: false}
    ]);
    this.jsonDragger = this.$utils.JSONDragger({
      onDragging: (o) =>
        this.$store.dispatch("dashboard/dragging", o ? "panel" : ""),
      image_url:
        "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+CjxzdmcKICAgd2lkdGg9IjEwMHB0IgogICBoZWlnaHQ9IjEwMHB0IgogICB2ZXJzaW9uPSIxLjEiCiAgIHZpZXdCb3g9IjAgMCAxMDAgMTAwIgogICBpZD0ic3ZnNCIKICAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogICB4bWxuczpzdmc9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiBmaWxsPSJub25lIj4KICA8cGF0aAogICAgIGQ9Im0gNTAsMCAtMTcuNTQ0ODMyLDE3LjU0NDgzMiA1LjI3MDUwMiw1LjI3MDUwMSA4LjU0Njc0NiwtOC41NDY3NDUgViAzNS4wODk2NjQgSCA1My43MjcwOCBWIDE0LjI2ODU4OCBsIDguNTQ2NzQ2LDguNTQ2NzQ1IDUuMjcwNTAyLC01LjI3MDUwMSB6IE0gMTcuNTQ0ODMyLDMyLjQ1NTE2OCAwLDUwIDE3LjU0NDgzMiw2Ny41NDQ4MzIgMjIuODE1MzMzLDYyLjI3NDMzIDE0LjI2ODU4OCw1My43Mjc1ODQgSCAzNS4wOTA2NzEgViA0Ni4yNzI5MiBIIDE0LjI2OTU5NSBsIDguNTQ2NzQ2LC04LjU0Njc0NiB6IG0gNjQuOTEwMzM2LDAgLTUuMjcwNTAxLDUuMjcwNTAyIDguNTQ2NzQ1LDguNTQ2NzQ2IC0yMC44MjEwNzYsMTBlLTQgdiA3LjQ1NDY2NSBoIDIwLjgyMTA3NiBsIC04LjU0Njc0NSw4LjU0Njc0NiA1LjI3MDUwMSw1LjI3MDUwMSBMIDEwMCw1MC4wMDA1MDQgWiBNIDQ2LjI3MjQxNiw2NC45MTAzMzYgViA4NS43MzE0MTIgTCAzNy43MjU2Nyw3Ny4xODQ2NjcgMzIuNDU1MTY4LDgyLjQ1NTE2OCA1MCwxMDAgNjcuNTQ0ODMyLDgyLjQ1NTE2OCA2Mi4yNzQzMyw3Ny4xODQ2NjcgNTMuNzI3NTg0LDg1LjczMTQxMiBWIDY0LjkxMDMzNiBaIgogICAgIHN0eWxlPSJzdHJva2Utd2lkdGg6MC4yNTE4NjQ7ICIgZmlsbD0iIzY2NiIvPgo8L3N2Zz4K"
    });
  },
  i18n: {messages}
};
</script>

<style scoped>
.dashboard-inner {
  width: 100%;
  padding: 20px 0 0 0;
  position: relative;
}

.dashboard-inner.resizing::after {
  /* maz */
  display: block;
  position: absolute;
  background-color: transparent;
  background-image: url("/static/common/images/bootstrap-grid.svg");
  background-size: 100%;
  background-position: 0 0;
  content: "";
  top: 0;
  left: -16px;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.4;
}

.dashboard-inner > div.row {
  position: relative;
}

/* .dashboard-inner > div.row.dragging {
  outline: 1px solid red;
} */

.dashboard-inner > div.row .drop-zone {
  position: absolute;
  left: 0px;
  top: -5px;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 0;
}

.dashboard-inner > div.row.dragging .drop-zone {
  background-color: rgba(255, 166, 0, 0.05);
}

.dashboard-inner > div.row > div.layout-toolbar {
  position: absolute;
  top: -20px;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  color: transparent;
  border-bottom: 1px solid transparent;
  text-align: right;
  overflow: hidden;
}

.dashboard-inner > div.row > div.layout-toolbar > div:hover {
  cursor: pointer;
  margin: 0px 15px;
  color: #8d1a1a;
  background-color: rgba(0, 0, 0, 0.144);
  opacity: 1;
  z-index: 0;
  border-color: rgb(180, 86, 86);
  border-radius: 8px 8px 0 0;
}

.dashboard-inner > div.row > div.layout-toolbar > div > span:hover {
  color: #b45656;
  opacity: 0.8;
}

.dashboard-inner > div.row > div.layout-toolbar > section.page-break-indicator {
  position: absolute;
  border-bottom: 1px dashed #8d1a1a;
  width: calc(100% - 30px);
  height: 1px;
  left: 15px;
  top: 21px;
  text-align: left;
  color: #a78e8e;
}

.dashboard-inner
  > div.row
  > div.layout-toolbar
  > section.page-break-indicator
  > i {
  top: -14px;
  left: 0;
  position: absolute;
}

div.resizing:hover {
  cursor: col-resize;
}

div.painel-container {
  position: relative;
}

div.painel-container.disabled {
  opacity: 0.6;
}

div.painel-container > div.disabled:hover {
  cursor: pointer;
  opacity: 0.9;
  z-index: 0;
}

div.painel-container.disabled > div.panel-handler {
  position: absolute;
  cursor: pointer;
  opacity: 1;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 100%;
  height: calc(100% - 6px);
  background-color: transparent;
  z-index: 100;
  overflow: hidden;
}

div.painel-container.disabled:hover > div.panel-handler {
  margin: -2px 10px -2px 0px;
  border: 5px solid #e9d0a2c5;
  border-radius: 5px;
}

div.painel-container.dragging > div.panel-handler,
div.painel-container.disabled:hover > div.panel-handler {
  margin: -2px 10px -2px 0px;
  border: 5px solid #e9d0a2c5;
  border-radius: 5px;
}

div.painel-container.disabled > div.panel-handler > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* drag information */
div.painel-container.disabled > div.panel-handler > div.drag-info > div {
  line-height: 2em;
  font-size: 12pt;
  font-weight: 600;
  color: transparent;
  opacity: 0;
}

div.painel-container.disabled:hover > div.panel-handler > div.drag-info > div {
  opacity: 1;
  color: #337ab7;
  transition: opacity 1s, color 100ms step-end;
}

div.painel-container.disabled > div.panel-handler > div.container-info > div {
  opacity: 0.6;
}
div.painel-container.disabled:hover
  > div.panel-handler
  > div.container-info
  > div {
  opacity: 0;
  transition: opacity 300ms ease-out;
}

.panel-resize-handler {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  height: calc(100% + 10px);
  width: 5px;
  background-color: transparent;
  color: transparent;
  overflow: visible;
  z-index: 9999;
}

.panel-resize-handler > div {
  height: 100%;
  width: 5px;
  margin: -10px 0 0 3px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  z-index: 9999;
}

.panel-resize-handler > div > div {
  width: 3px;
  height: 100%;
  border-right: 1px solid transparent;
  z-index: 9999;
}

.panel-resize-handler:hover {
  cursor: col-resize;
  z-index: 9999;
}

.panel-resize-handler:hover > div {
  border-top-color: #2a2ae2;
  border-bottom-color: #2a2ae2;
  z-index: 9999;
}

.panel-resize-handler:hover > div > div {
  border-right-color: #2a2ae2;
  z-index: 9999;
}

.fa-eject {
  vertical-align: middle;
  text-shadow: none;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}
</style>
