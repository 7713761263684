<template>
  <div>
    <button
      class="btn btn-sm btn-primary"
      @click.stop.prevent="jsFloatEditor = true"
      :disabled="jsFloatEditor"
    >
      <i class="fa fa-pencil"></i>
      {{ $t("edit") }}
    </button>
    <JsFloatEditor
      v-if="jsFloatEditor && ready"
      @save="save"
      @open="open"
      @close="close"
      @remove="remove"
      @addItem="addItem"
      @removeItem="removeItem"
      :activeScript="activeScript"
      v-model="jsScriptContent"
    >
      <template #JSEditorToolbar>
        <form class="form form-inline">
          <div class="form-group form-group-sm">
            <!-- TODO: disabled once screen mode not yet implemented   -->
            <select class="form-control" v-model="scope" disabled>
              <option value="screen">{{ $tc("screen", 1) }}</option>
              <option value="contract">{{ $tc("global", 1) }}</option>
            </select>
          </div>
          <span
            class="clicable js-toolbar-button"
            @click.stop.prevent="onVersions"
            v-if="scriptId > 0"
          >
            <i class="fa fa-history"></i>
          </span>
          <span
            class="clicable js-toolbar-button"
            @click.stop.prevent="onSave"
            :title="$t('save')"
          >
            <i
              :class="
                status.id == 'saving' ? 'fa fa-refresh fa-spin' : 'fa fa-save'
              "
            ></i>
          </span>
        </form>
      </template>
    </JsFloatEditor>
    <portal to="modal" v-if="scriptId > 0">
      <RestoreScreenDialog
        v-if="modal == 'versions'"
        @close="onCloseRestoreDialog($event)"
        :screenId="scriptId"
      />
    </portal>
  </div>
</template>
<script>
// import Events from "@/assets/dashboard/events.json";
// import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
// import ControlActionSelector from "@/components/editor/control-action-selector.vue";
// import ControlEventActionForm from "@/components/editor/control-event-action-form.vue";
import JsFloatEditor from "@/components/editor/js-script-editor/js-float-editor.vue";
import RestoreScreenDialog from "@/components/editor/restore-screen-dialog.vue";
import { scriptDefaults } from "@/services/dashboard.js";

import ScreenService from "@/services/screen";
import ContractService from "@/services/contract.js";

export default {
  name: "ScriptsForm",
  components: {
    JsFloatEditor,
    RestoreScreenDialog
  },
  data() {
    return {
      ready: false,
      busy: false,
      modal: "",
      jsFloatEditor: false,
      formData: scriptDefaults().newScreen,
      scope: "contract"
    };
  },
  computed: {
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    scriptList() {
      return this.$store.getters["dashboard/scriptList"] || [];
    },
    scriptId() {
      return this?.activeScript?.id ?? 0;
    },
    activeScript() {
      return this.$store.getters["scripts/activeScript"];
    },
    jsScriptContent: {
      set(value) {
        if (this.scope == 'contract') {
          return this.$store.dispatch("scripts/setContent", value)
        }
        else {

        }
      },
      get() {
        if (this.scope == 'contract') {
          return this.$store.getters["scripts/content"];
        }
        else {
          return "";
        }
      }
    },
    status() {
      return this.$store.getters["scripts/status"];
    }
  },
  watch: {
    scope(n) {
      this.ready = false;
      this.$nextTick(() => { this.ready = true })
    }
  },
  methods: {
    open() {
      this.jsFloatEditor = true;
    },
    close() {
      this.modal = "";
      this.jsFloatEditor = false;
    },
    remove() {
      // TODO: might not be wise to allow removal operation (maz)
    },
    save() {
      return this.$store.dispatch("scripts/save");
    },
    onSave() {
      if (!this?.contract?.id) return;
      this.save().then((resp) => {
        if (resp && resp.id && resp.path) {
          this.validate(resp);
        }
      });
    },
    validateContractScript(entry) {
      // initialize a contract level script, if needed
      if (!this?.contract?.id) return;
      if (this.scriptList.length && !this.activeScript) {
        this.updateContract(entry || this.scriptList[0]);
      }
    },
    updateContract(entry) {
      // if (!this.can || !this.screenPath || this.busy) return;
      let payload = {
        portal_data: JSON.parse(
          JSON.stringify(this?.contract?.portal_data || {})
        ),
        etag: this?.contract?.etag || "",
        id: this?.contract?.id || ""
      };
      if (!payload.id || !payload.etag) return null;
      // TODO: Remove line below when multiple scripts are suported
      payload.portal_data.scripts = [
        {
          id: entry.id,
          path: entry.path
        }
      ];
      // for testing purpose:
      // delete payload.portal_data.scripts;
      this.savePortalData(payload);
    },
    savePortalData(payload) {
      this.busy = true;
      let srv = new ContractService();
      srv.updateContract(payload).then(
        (response) => {
          this.busy = false;
          if (response && typeof response == "object" && response.id) {
            this.$store.dispatch("user/configureUserContract");
            this.$nextTick(() => {
              if (!this.jsScriptContent) {
                this.$store.dispatch("scripts/fetchScriptContent", null, { root: true });
              }
            });
          } else {
            this.$swal({
              title: this.$t("item_could_not_be_saved"),
              text: this.$t("invalid_data"),
              icon: "error"
            });
          }
        },
        (error) => {
          this.busy = false;
          this.$swal({
            title: this.$t("item_could_not_be_saved"),
            text: `${error?.status || ""} ${error?.statusText ||
              this.$t("invalid_data")}`,
            icon: "error"
          });
        }
      );
    },
    addItem() {
      // May it should it be saved
    },
    removeItem() {
      this.onSave();
    },
    onVersions() {
      this.close();
      this.modal = "versions";
    },
    onCloseRestoreDialog(goto_path) {
      this.onCloseModal();
      if (goto_path == "edit") {
        this.$nextTick(() => {
          setTimeout(this.open, 500, this);
        });
      }
    },
    onCloseModal() {
      this.modal = "";
    }
  },
  created() {
    this.validateContractScript();
    this.ready = true;
  },
  beforeCreate() {
    this.srv = new ScreenService();
  },
  beforeDestroy() {
    this.srv = null;
  }
};
</script>

<style scoped>
.js-toolbar-button {
  margin: 0 10px;
}
.form-inline > div {
  margin-top: -5px;
  margin-right: 10px;
  vertical-align: middle;
}

.form-inline > div > select {
  padding: 0 10px;
  line-height: 22px;
  height: 22px;
}
</style>
