<template>
  <div class="js-script-editor">
    <FileExplorerContainer>
      <template #sidebar>
        <JSScriptSideBar
          :code="code"
          :active="active"
          @select="select"
          @addItem="addItem"
          @removeItem="removeItem"
        />
      </template>
      <template #main>
        <JSScriptMain
          v-model="activeContent"
          v-if="!switching"
          @save="$emit('save')"
          @ready="ready = true"
        />
        <div v-if="!ready" class="loading">
          <i class="fa fa-refresh fa-spin"></i>
        </div>
      </template>
    </FileExplorerContainer>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { scriptDefaults } from "@/services/dashboard.js";
import FileExplorerContainer from "../file-explorer-container.vue";
import JSScriptSideBar from "./js-script-sidebar.vue";
import JSScriptMain from "./js-script-main.vue";

export default {
  name: "JsScriptEditor",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    FileExplorerContainer,
    JSScriptSideBar,
    JSScriptMain
  },
  data: () => ({
    switching: false,
    ready: false,
    code: {},
    active: ""
  }),
  computed: {
    activeContent: {
      set(value) {
        if (this.active) {
          this.code[this.active] = value;
          this.updateParent("update");
        }
      },
      get() {
        return this.active ? this.code[this.active] : "";
      }
    }
  },
  methods: {
    select(fnName) {
      this.active = this.active == fnName ? "" : fnName;
      this.switching = true;
      this.$nextTick(() => {
        this.switching = false;
      });
    },
    addItem(id) {
      let template = scriptDefaults().newFunction;
      let user = this.$store.getters["user/loggedUser"];
      let entry = {
        ...user,
        function_name: id,
        created: moment().format("L LTS")
      };
      if (Object.keys(entry).length) {
        template = this.$utils.replaceBulk(
          template,
          Object.keys(entry).map((v) => `$${v}`),
          Object.values(entry)
        );
      }
      this.$set(this.code, `${id}`, template);
      this.updateParent("addItem");
    },
    removeItem(id) {
      this.$delete(this.code, id);
      if (this.active == id) {
        this.select(id);
      }
      this.updateParent("removeItem");
    }
  },
  created() {
    this.code = JSON.parse(this.value || "{}");
    this.updateParent = debounce((action) => {
      this.$emit("input", JSON.stringify(this.code));
      if (action) {
        this.$nextTick(() => {
          this.$emit(action);
        });
      }
    }, 300);
  }
};
</script>

<style scoped>
div.js-script-editor {
  padding: 0;
  margin: 0;
  height: inherit;
}

div.loading {
  text-align: center;
  margin-top: 50px;
  font-size: 18pt;
  color: #888;
  cursor: wait;
}
</style>
