<template>
  <portal to="contextmenu" v-if="floatPanel.open">
    <FloatPanel
      class="float-panel"
      :draggable="true"
      :handleSelf="true"
      :defaultPosition="floatPanel.rect"
      :open="floatPanel.open"
      @update:open="onFloatPanelUpdate($event)"
      @dragstart="floatPanel.dragging = true"
      @dragend="onDragEnd(floatPanel, $event)"
    >
      <div class="popup">
        <div class="header">
          <i class="fa fa-file-code-o" />
          {{ $t("javascript_editor") }}
        </div>
        <div class="popup-body">
          <JsScriptEditor
            v-model="iValue"
            @addItem="$emit('addItem')"
            @removeItem="$emit('removeItem')"
            @save="$emit('save')"
          />
          <span class="js-editor-toolbar">
            <slot name="JSEditorToolbar"> </slot>
          </span>
        </div>
      </div>
      <template v-if="activeScript">
        <div class="file-info small" :title="fileInfo">
          <div class="pull-left">
            <span class="">
              {{ activeScript.name }}
            </span>
            -
            <span class=""> v{{ activeScript.revision_code }} </span>
          </div>
          <div class="pull-right">
            <span class="">
              {{
                activeScript.updated_at
                  ? $dt.format(activeScript.updated_at)
                  : ""
              }}
            </span>
          </div>
        </div>
      </template>
    </FloatPanel>
  </portal>
</template>

<script>
import JsScriptEditor from "./index.vue";
import FloatPanel from "@/components/editor/float-panel.vue";
const _fpsize = [800, 500];
let _fp = {
  w: _fpsize[0],
  h: _fpsize[1],
  x: parseInt((window.innerWidth - _fpsize[0]) / 2),
  y: 70
};
_fp.x = _fp.x < 0 ? 0 : _fp.x;
export default {
  name: "JsFloatEditor",
  props: {
    value: {
      type: String,
      required: false,
      default: ""
    },
    activeScript: {
      type: Object,
      required: false,
      default: () => ({})
    }
  },
  components: {
    FloatPanel,
    JsScriptEditor
  },
  data() {
    return {
      floatPanel: {
        rect: {
          top: `${_fp.y}px`,
          left: `${_fp.x}px`
        },
        open: false,
        dragging: false
      }
    };
  },
  computed: {
    iValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    fileInfo() {
      return (
        (this.activeScript &&
          `${this.activeScript.name} - v${this.activeScript.revision_code
          } - published at: ${this.activeScript.updated_at
            ? this.$dt.format(this.activeScript.updated_at)
            : ""
          }`) ||
        ""
      );
    }
  },
  methods: {
    onDragEnd(floatPanel, $event) {
      floatPanel.top = $event.top;
      floatPanel.left = $event.left;
      floatPanel.dragging = false;
    },
    onFloatPanelUpdate(e) {
      console.log(e);
      this.$emit(e ? "open" : "close");
    }
  },
  mounted() {
    this.floatPanel.open = true;
  },
  beforeDestroy() {
    this.floatPanel.open = false;
  }
};
</script>

<style scoped>
.clicable:hover {
  opacity: 0.8;
  cursor: pointer;
}

.float-panel {
  padding: 30px 10px 10px 10px;
  overflow: hidden;
}

.float-panel:hover {
  cursor: move;
}

.popup {
  position: relative;
  height: 100%;
}

.popup > div.header {
  position: absolute;
  top: -26px;
  color: #666;
  font-weight: 600;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

.popup > .popup-body {
  display: flex;
  background-color: transparent;
  /* border: 1px solid lightgray; */
  border-width: 0;
  margin: 0;
  resize: both;
  overflow: auto;
  height: calc(100vh - 40vh);
  min-height: 200px;
  max-height: calc(100vh - 200px);
}

.popup > .popup-body > div {
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  min-height: 100%;
  height: 100%;
}

.js-editor-toolbar {
  position: absolute;
  right: 20px;
  top: -25px;
  color: gray;
}

.file-info {
  color: #666;
}
</style>
