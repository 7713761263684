<template>
  <div class="js-script-editor-explorer no-select" :style="cssvars">
    <div
      :class="{
        'sidebar-opened': sidebarHandler,
        animated: $attrs.animated,
        'sidebar-lg': $attrs.sidebarLarge
      }"
    >
      <slot name="sidebar"></slot>
      <div class="resize-handlebar"></div>
    </div>
    <div>
      <slot name="main"></slot>
      <div v-if="disabled" class="disabled" />
    </div>
  </div>
</template>

<script>
function SideBarHandler(selector) {
  this.el = document.querySelector(selector);
  this.isDragging = false;
  this.onResize = () => {};
  this.mouseDown = (e) => {
    if (e.target === this.el) {
      e.stopPropagation();
      this.isDragging = true;
      document.addEventListener("mousemove", this.mouseMove);
      document.addEventListener("mouseup", this.mouseUp);
      if (this.onResize && typeof this.onResize == "function") {
        this.onResize("start");
      }
    } else {
      document.removeEventListener("mousemove", this.mouseMove);
      document.removeEventListener("mouseup", this.mouseUp);
      return false;
    }
  };
  this.mouseMove = (e) => {
    if (!this.isDragging) {
      document.removeEventListener("mousemove", this.mouseMove);
      document.removeEventListener("mouseup", this.mouseUp);
      return false;
    }
    e.stopPropagation();
    this.el.parentNode.style.width = `${
      e.clientX - this.el.parentNode.getBoundingClientRect().left
    }px`;
    this.el.parentNode.style.flexGrow = 0;
  };
  this.mouseUp = (e) => {
    e.stopPropagation();
    document.removeEventListener("mousemove", this.mouseMove);
    document.removeEventListener("mouseup", this.mouseUp);
    if (!this.isDragging) return false;
    this.isDragging = false;
    if (this.onResize && typeof this.onResize == "function") {
      this.onResize("stop");
    }
  };
  this.init = () => {
    document.addEventListener("mousedown", this.mouseDown);
  };
  this.destroy = () => {
    document.removeEventListener("mousedown", this.mouseDown);
    document.removeEventListener("mousemove", this.mouseMove);
    document.removeEventListener("mouseup", this.mouseUp);
  };
}

export default {
  name: "FileExplorerContainer",
  data() {
    return {
      sidebarHandler: null,
      cssvars: {
        "--min-height": "600px"
      },
      disabled: false
    };
  },
  methods: {
    sidebar() {
      setTimeout(
        () => {
          this.sidebarHandler = new SideBarHandler(".resize-handlebar");
          this.sidebarHandler.init();
          this.sidebarHandler.onResize = (opt) => {
            this.disabled = opt == "start";
            if (opt == "stop") {
              this.disabled = false;
              this.$nextTick(() => {
                if (!this.sidebarHandler) return;
                this.$emit("sidebarResize");
              });
            }
          };
        },
        0,
        this
      );
    }
  },
  mounted() {
    this.sidebar();
    if (this.$route.path.includes("/edit/")) {
      this.cssvars["--min-height"] = `calc(100dvh - ${Math.round(
        this.$el.parentNode.getBoundingClientRect().top + 194
      )}px)`;
    } else {
      this.cssvars["--min-height"] = `calc(100vh - ${
        Math.round(this.$el.getBoundingClientRect().top) + 50
      }px)`;
    }
  },
  beforeDestroy() {
    this.sidebarHandler.destroy();
    this.sidebarHandler = null;
  }
};
</script>

<style scoped>
.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

div.js-script-editor-explorer {
  display: flex;
  align-items: stretch;
  min-width: 800px;
  /* min-height: 400px; */
  min-height: var(--min-height);
  background-color: transparent;
  border-top: 1px solid lightgray;
  /* border-radius: 5px; */
  resize: both;
  /* overflow: auto; */
  padding: 0;
  height: inherit;
}

div.js-script-editor-explorer > div:first-child {
  position: relative;
  display: block;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  /* background-color: #f5f5f5; */
  border-right: 1px solid lightgray;
  padding: 0;
  min-width: 150px;
  width: 230px;
  overflow: hidden;
  margin-left: 0;
}

div.js-script-editor-explorer > div:first-child.sidebar-lg {
  min-width: 60px;
}

div.js-script-editor-explorer > div.animated:first-child {
  margin-left: -150px;
  transition: margin 0.25s ease-out;
}

div.js-script-editor-explorer > div.animated.sidebar-opened:first-child {
  margin-left: 0;
}

div.js-script-editor-explorer > div:last-child {
  position: relative;
  flex-grow: 1;
  min-width: 50%;
}

div.resize-handlebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 4px;
  background-color: #ededed;
}

div.resize-handlebar:hover {
  cursor: col-resize;
  background-color: #f4f4f4;
}

div.disabled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  opacity: 0.3;
  z-index: 999;
  pointer-events: none;
}
</style>
