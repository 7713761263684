<template>
  <div v-if="ctrlComponent">
    <ControlEventForm
      v-for="(item, ix) in eventNameList"
      :key="ix"
      :control="control"
      :eventName="item"
      @delEvent="$emit('delEvent', $event)"
      @collapsed="formCollapsed = $event"
      ref="ControlEventForm"
    />
    <ControlEventSelector
      :control="control"
      @addEvent="$emit('addEvent', $event)"
      v-if="formCollapsed"
    />
  </div>
</template>

<script>
// import Events from "@/assets/dashboard/events.json";
import ControlEventForm from "@/components/editor/control-event-form.vue";
import ControlEventSelector from "@/components/editor/control-event-selector.vue";

export default {
  name: "ControlEventEditor",
  components: {
    ControlEventSelector,
    ControlEventForm
  },
  props: {
    control: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data() {
    return {
      formCollapsed: false
    }
  },
  watch: {
    eventNameList: {
      handler(n) {
        if (!n || !n.length) {
          this.formCollapsed = true;
        }
      },
      immediate: true
    }
  },
  computed: {
    isSynopticComponent() {
      if (!this.control) return false;
      return this.control.synopticComponent;
    },
    ctrlComponent() {
      if (!this.control) return null;
      return this.isSynopticComponent ? this.control.synopticComponent : this.control;
    },
    eventNameList() {
      if (!this.control) return [];
      return Object.keys(this?.ctrlComponent?.on || {});
    }
  },
  methods: {
    collapseAll() {
      this.$nextTick(() => {
        (this?.$refs?.ControlEventForm || []).forEach((i) => (i.collapsed = true))
      });
    }
  }
};
</script>

<style scoped></style>
