<template>
  <div class="form-group">
    <label>
      {{ $t(label) }}
      <Tooltip :title="$t(tooltip)" />
    </label>
    <select
      v-model="iValue"
      class="form-control"
      @change="onChange"
      :title="$t(title)"
    >
      <option
        v-for="(item, ix) in items"
        v-bind:key="ix"
        v-bind:value="item.value"
        >{{ item.label }}</option
      >
    </select>
  </div>
</template>

<script>
import Tooltip from "@/components/tooltip.vue";

export default {
  name: "PoolingIntervalSelector",
  components: {
    Tooltip
  },
  props: {
    value: {
      type: Number,
      default: 30000,
      required: false
    },
    label: {
      type: String,
      default: "pooling_interval",
      required: false
    },
    title: {
      type: String,
      default: "pooling_interval",
      required: false
    },
    tooltip: {
      type: String,
      default: "pooling_interval_ui",
      required: false
    },
    list: {
      type: Array,
      default: () => [],
      required: false
    }
  },
  data() {
    return {
      iValue: 0
    };
  },
  computed: {
    items() {
      return (
        (this?.list?.length && this.list) ||
        this?.$root?.config?.pooling_interval?.options || [
          { value: 30000, label: "30s" },
          { value: 60000, label: "1min" },
          { value: 150000, label: "2.5 min" },
          { value: 300000, label: "5 min" },
          { value: 600000, label: "10 min" },
          { value: 900000, label: "15 min" }
        ]
      );
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.iValue);
    }
  },
  mounted() {
    this.iValue = this.value;
  }
};
</script>

<style scoped></style>
