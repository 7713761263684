<template>
  <section>
    <ControlStyleProperties v-model="contentStyle">
      <template #text_content>
        <div>
          <label>{{ $t("content") }}</label>
          <RichText v-model="content" :mode.sync="mode" />
        </div>
      </template>
    </ControlStyleProperties>
  </section>
</template>

<script>
import RichText from "@/components/editor/rich-text.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import Panels from "@/assets/dashboard/panels.json";

const defStyle = () => {
  let panel = (Panels || []).find(
    (panel) => panel.template.template == "DashboardRichTextPanel"
  );
  return (panel || {})?.template?.options?.style || {};
};

export default {
  name: "DetailFormRichText",
  components: {
    ControlStyleProperties,
    RichText
  },
  data() {
    return {
      mode: "rich"
    };
  },
  computed: {
    content: {
      set(value) {
        if (this?.panel?.options) {
          let panel = this.panel;
          panel.options.content = value;
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        return this?.panel?.options?.content || "";
      }
    },
    contentStyle: {
      set(value) {
        let panel = this.panel;
        if (panel && panel?.options) {
          this.$set(panel.options, "style", value);
          this.$store.dispatch("dashboard/saveDraftPanel", {
            screenId: this.screenId,
            panel: panel
          });
        }
      },
      get() {
        let panel = this.panel;
        if (panel && panel?.options) {
          return (
            panel?.options?.style || JSON.parse(JSON.stringify(defStyle()))
          );
        }
        return null;
      }
    },
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || { screenId: "" })
        .screenId;
    }
  },
  methods: {
    onRichTextEvent($event) {
      switch ($event.action) {
        case "content:change": {
          this.mode = "plain";
          this.content = $event.details;
          break;
        }
      }
    }
  },
  created() {
    this.$root.$on("richtext:event", this.onRichTextEvent);
  },
  beforeDestroy() {
    this.$root.$off("richtext:event", this.onRichTextEvent);
  }
};
</script>

<style scoped></style>
