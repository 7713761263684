<template>
  <DetailForm persistent="toggle_dashboard_toolbar">
    <DetailFormToolbar />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormToolbar from "@/components/control-sidebar/property-editors/detail-form-toolbar.vue";

export default {
  name: "EquipmentToolbarForm",
  components: {
    DetailForm,
    DetailFormToolbar
  }
};
</script>
