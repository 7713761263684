var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"inline"}},[_c('div',{ref:"iframe_dialog",staticClass:"modal fade in"},[_c('div',{staticClass:"modal-dialog modal-lg"},[_c('div',{staticClass:"modal-content"},[(_vm.dialog.title)?_c('div',{staticClass:"modal-header"},[_vm._m(0),_c('h4',{staticClass:"modal-title"},[_vm._v(_vm._s(_vm.dialog.title))])]):_c('div',{staticStyle:{"position":"relative","z-index":"1"}},[_vm._m(1)]),_c('div',{staticClass:"modal-body"},[(_vm.dialog.src)?_c('iframe',{ref:"iframe",staticClass:"scrollable-iframe fade in",attrs:{"src":_vm.dialog.src},on:{"load":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onModalIframeLoaded.apply(null, arguments)}}}):_vm._e()])])])]),_c('a',{ref:"newtab_link",staticStyle:{"display":"none"},attrs:{"href":"","target":"_blank"}}),_vm._l((_vm.popup_list),function(popup,ix){return _c('FloatPanel',{key:ix,staticClass:"float-panel",attrs:{"draggable":true,"handleSelf":true,"defaultPosition":{top: popup.position.top, left: popup.position.left},"open":popup.src ? true : false,"title":popup.title},on:{"update:open":function($event){popup.src = $event ? popup.src : ''},"dragstart":function($event){popup.dragging = true},"dragend":function($event){return _vm.onPopupDragged(popup, $event)}}},[_c('div',{style:({
        width: popup.position.width,
        height: popup.position.height,
        position: 'relative'
      })},[(popup.src && !popup.dragging)?_c('iframe',{staticClass:"scrollable-iframe fade in",attrs:{"id":popup.id,"name":popup.id,"src":popup.src},on:{"load":function($event){$event.stopPropagation();$event.preventDefault();return _vm.onPopUpIframeLoaded(popup, $event)}}}):_vm._e()])])})],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"absolute","right":"0px","top":"0px","/* font-size":"18pt"}},[_c('span',{staticClass:"btn",staticStyle:{"padding":"0px 4px","font-size":"15px","line-height":"1.5"},attrs:{"data-dismiss":"modal","aria-label":"Close"}},[_c('i',{staticClass:"fa fa-close"})])])
}]

export { render, staticRenderFns }