<template>
  <div
    class="keyboard-container"
    tabindex="0"
    @keydown.esc.exact="trigger($event, 'clear-selection')"
    @keydown.delete.exact="trigger($event, 'delete')"
    @keydown.ctrl.a.exact="trigger($event, 'select-all')"
    @keydown.ctrl.c.exact="trigger($event, 'copy')"
    @keydown.ctrl.x.exact="trigger($event, 'cut')"
    @keydown.ctrl.v.exact="trigger($event, 'paste')"
    @keydown.ctrl.z.exact="trigger($event, 'undo')"
    @keydown.ctrl.shift.z.exact="trigger($event, 'redo')"
    @keydown.up.exact="trigger($event, 'arrows', { direction: 'up' })"
    @keydown.down.exact="trigger($event, 'arrows', { direction: 'down' })"
    @keydown.left.exact="trigger($event, 'arrows', { direction: 'left' })"
    @keydown.right.exact="trigger($event, 'arrows', { direction: 'right' })"
    @keydown.shift.up.exact="
      trigger($event, 'arrows', { direction: 'up', shiftKey: true })
    "
    @keydown.shift.down.exact="
      trigger($event, 'arrows', { direction: 'down', shiftKey: true })
    "
    @keydown.shift.left.exact="
      trigger($event, 'arrows', { direction: 'left', shiftKey: true })
    "
    @keydown.shift.right.exact="
      trigger($event, 'arrows', { direction: 'right', shiftKey: true })
    "
    @keydown.ctrl.up.exact="
      trigger($event, 'arrows', { direction: 'up', ctrlKey: true })
    "
    @keydown.ctrl.down.exact="
      trigger($event, 'arrows', { direction: 'down', ctrlKey: true })
    "
    @keydown.ctrl.left.exact="
      trigger($event, 'arrows', { direction: 'left', ctrlKey: true })
    "
    @keydown.ctrl.right.exact="
      trigger($event, 'arrows', { direction: 'right', ctrlKey: true })
    "
    @keydown.ctrl.shift.up.exact="
      trigger($event, 'arrows', {
        direction: 'up',
        ctrlKey: true,
        shiftKey: true
      })
    "
    @keydown.ctrl.shift.down.exact="
      trigger($event, 'arrows', {
        direction: 'down',
        ctrlKey: true,
        shiftKey: true
      })
    "
    @keydown.ctrl.shift.left.exact="
      trigger($event, 'arrows', {
        direction: 'left',
        ctrlKey: true,
        shiftKey: true
      })
    "
    @keydown.ctrl.shift.right.exact="
      trigger($event, 'arrows', {
        direction: 'right',
        ctrlKey: true,
        shiftKey: true
      })
    "
    @contextmenu="trigger($event, 'contextmenu', $event)"
    @drop.prevent="(e) => $emit('drop', e)"
    @dragover.prevent
    @focus.stop
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "KeyboardHandler",
  data() {
    return {
      listening: true
    };
  },
  methods: {
    trigger($event, action, params) {
      if (!this.listening) return;
      $event.stopPropagation();
      $event.preventDefault();
      if (params) {
        this.$root.$emit(action, params);
      } else {
        this.$root.$emit(action);
      }
    },
    stop() {
      this.listening = false;
    },
    start() {
      this.listening = true;
    },
    focus() {
      if (this.$el) {
        // console.log("focus");
        this.$el.focus({ preventScroll: true });
      }
    }
  },
  created() {
    this.$root.$on("editor.keyboard:stop", this.stop);
    this.$root.$on("editor.keyboard:start", this.start);
    this.$root.$on("editor.keyboard:focus", this.focus);
  },
  beforeDestroy() {
    this.$root.$off("editor.keyboard:start", this.start);
    this.$root.$off("editor.keyboard:stop", this.stop);
    this.$root.$off("editor.keyboard:focus", this.focus);
  }
};
</script>

<style>
.keyboard-container {
  outline: none;
  position: relative;
}
</style>
