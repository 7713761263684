<template>
  <section v-if="chartOptions">
    <!-- chart header -->
    <TogglePanel
      :title="$tc('rich_text.heading')"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_panel_header"
    >
      <div class="inner-panel">
        <div class="form-group form-group-sm">
          <label class="label-inline" for="title">{{ $t("title") }}</label>
          <input
            :placeholder="`${(panel && panel.title) || $t('text')}`"
            class="form-control"
            v-model="titleText"
          />
        </div>
        <InlineDimensionForm
          v-if="target"
          title="padding"
          :lockbles="[0, 1, 2, 3]"
          :labels="['top', 'right', 'left', 'bottom']"
          :disabled="titlePaddingDisabled"
          v-model="titlePadding"
        />
        <ControlStyleProperties v-if="panel" v-model="titleStyle">
          <template #padding>
            <InlineDimensionForm
              title="padding"
              :lockbles="[0, 1, 2, 3]"
              :labels="['top', 'right', 'left', 'bottom']"
              :disabled="titlePaddingDisabled"
              v-model="titlePadding"
            />
          </template>
        </ControlStyleProperties>
      </div>
    </TogglePanel>

    <!-- chart area (general)-->
    <TogglePanel
      :title="$tc('general')"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_panel_general"
    >
      <div class="inner-panel" style="margin-top: -5px">
        <ChartGeneralForm v-model="generalChartOptions" />
        <TogglePanel :title="`${$t('realtime')}`">
          <div class="checkbox" v-if="panel">
            <label>
              <input type="checkbox" v-model="panelOptions.realtime" />
              {{ $t("realtime_update") }}
              <Tooltip :title="$t('hints.realtime_chart')" />
            </label>
          </div>
          <div
            v-if="target || (panelOptions && panelOptions.realtime)"
            class="inner-box"
          >
            <div class="form-group form-group-sm">
              <label
                >{{ $t("refresh_interval") }}
                <Tooltip :title="$t('refresh_interval_desc')"
              /></label>
              <TimeInterval
                v-model="chartOptions.refreshInterval"
                options="s,m,h"
                style="width: 100%"
              />
            </div>
            <div class="form-group form-group-sm">
              <label
                >{{ $t("time_window") }}
                <Tooltip :title="$t('time_window_desc')"
              /></label>
              <TimeInterval
                v-model="chartOptions.timeWindow"
                options="s,m,h"
                style="width: 100%"
              />
            </div>
            <div class="checkbox">
              <label for="trailing-value">
                <input
                  id="trailing-value"
                  v-model="chartOptions.trailingValue"
                  type="checkbox"
                  data-testid="trailing-value" />{{ $t("trailing_value") }}
                <Tooltip :title="$t('trailing_value_desc')"
              /></label>
            </div>
          </div>
        </TogglePanel>
        <!-- disconnection form -->
        <DisconnectionChartForm
          v-model="disconnectionForm"
          v-if="panel && panel.template == 'EquipmentHistoryChartPanel'"
        />
        <!-- disconnection form -->
      </div>
    </TogglePanel>

    <!-- legends -->
    <TogglePanel
      :title="$tc('rich_text.legend', 1)"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
      persistent="toogle_chart_panel_legend"
    >
      <div class="content-padding">
        <div
          class="row"
          style="border-radius: 5px; padding: 5px 0; margin-top: -10px"
        >
          <div class="col-xs-8">
            <label
              style="margin-top: 14px; white-space: nowrap"
              class="checkbox-inline checkbox-inline-bottom-margin no-select"
            >
              <input type="checkbox" v-model="chartOptions.legend.show" />
              {{ $t("show_at_position") }}
            </label>
          </div>
          <div
            class="col-xs-4 text-center"
            style="padding-right: 60px"
            :style="{ opacity: chartOptions.legend.show ? 1 : 0.5 }"
          >
            <AlignSelector v-model="legendPosition" style="zoom: 0.8" />
          </div>
        </div>
        <InlineDimensionForm
          v-if="chartOptions.legend.show"
          title="padding"
          :lockbles="[0, 1, 2, 3]"
          :labels="['top', 'right', 'left', 'bottom']"
          v-model="legendPadding"
          :disabled="legendPaddingDisabled"
        />
      </div>
    </TogglePanel>

    <!-- data series -->
    <ChartSeriesForm
      v-model="chartSeriesConfig"
      :changeType="
        target || (panelOptions && panelOptions.realtime) ? false : true
      "
      :showApplyExpresson="!target"
      :showInterpolation="!target"
      :checkable="checkable"
      :dataListParser="dataListParser"
    />
  </section>
</template>

<script>
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
import ControlStyleProperties from "@/components/synoptic/property-editor/controls/control-style-properties.vue";
import AlignSelector from "@/components/widgets/align-selector.vue";
import TimeInterval from "@/components/editor/time-interval.vue";
import InlineDimensionForm from "@/components/synoptic/property-editor/controls/inline-dimension-form";
import Tooltip from "@/components/tooltip.vue";
import Chart from "@/assets/dashboard/chart.json";
import ChartSeriesForm from "@/components/control-sidebar/property-editors/chart-series-form.vue";
import ChartGeneralForm from "@/components/control-sidebar/property-editors/chart-general-form.vue";
import isEqual from "lodash/isEqual";
import DisconnectionChartForm from "@/components/control-sidebar/property-editors/disconnection-chart-form.vue";
const defSerie = (type) => {
  return JSON.parse(JSON.stringify(Chart.seriesOptions[type || "line"]));
};
const titleStyleProperty = {
  set(vlr) {
    if (this?.chartOptions?.title) {
      let title = {
        left: vlr["text-align"],
        padding:
          vlr.padding instanceof Array
            ? vlr.padding
            : this.chartOptions.title.padding,
        backgroundColor: vlr["background-color"],
        textAlign: vlr["text-align"],
        textStyle: {
          color: vlr.color,
          fontFamily: vlr["font-family"],
          fontSize: parseInt(vlr["font-size"]),
          fontStyle: vlr["font-style"],
          fontWeight: vlr["font-weight"]
        }
      };

      this.$set(
        this.chartOptions,
        "title",
        Object.assign({ ...this.chartOptions.title }, title)
      );
    }
  },
  get() {
    let style = {
      color: this.chartOptions.title.textStyle.color,
      padding: this.chartOptions.title.padding,
      "background-color":
        this.chartOptions.title.backgroundColor || "transparent",
      "font-family": this.chartOptions.title.textStyle.fontFamily,
      "font-size":
        (this.chartOptions.title.textStyle.fontSize + "").replace(/\D/g, "") +
        "pt",
      "font-style": this.chartOptions.title.textStyle.fontStyle,
      "font-weight": this.chartOptions.title.textStyle.fontWeight,
      "text-align":
        this.chartOptions.title.textAlign ||
        this.chartOptions.title.left ||
        "center"
    };
    return style;
  }
};

//
// it returns input dimension controls that must be disabled according to the position
// top,right,left,down
const disabledDimensions = (position) => {
  let ref = {
    "top-left": [false, true, false, true],
    "top-center": [false, true, true, true],
    "top-right": [false, false, true, true],
    "middle-left": [true, true, false, true],
    "middle-center": [true, true, true, true],
    "middle-right": [true, false, true, true],
    "bottom-left": [true, true, false, false],
    "bottom-center": [true, true, true, false],
    "bottom-right": [true, false, true, false],
    left: [false, true, false, true],
    right: [false, false, true, true],
    center: [false, true, true, true]
  };
  return ref[position] || [false, false, false, false];
};

export { defSerie, titleStyleProperty };

export default {
  name: "DetailFormChart",
  components: {
    TogglePanel,
    ControlStyleProperties,
    AlignSelector,
    TimeInterval,
    InlineDimensionForm,
    Tooltip,
    ChartSeriesForm,
    ChartGeneralForm,
    DisconnectionChartForm
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    checkable: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      info: null,
      curDataId: null,
      dataSelector: true,
      target: null
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    panel() {
      let currentDraftPanel =
        this.$store.getters["dashboard/currentDraftPanel"] || null;
      if (currentDraftPanel.template == "EquipmentHistoryChartPanel") {
        return currentDraftPanel;
      } else {
        return (
          (this?.info?.panelName &&
            (this?.draft?.template?.panels || []).find(
              (i) => i.name == this.info.panelName
            )) ||
          null
        );
      }
      // if (!this?.info?.panelName) return null;
      // return (
      //   (this.draft &&
      //     this.draft?.template &&
      //     (this.draft?.template?.panels || []).find(
      //       (i) => i.name == this.info.panelName
      //     )) ||
      //   null
      // );
    },
    panelOptions: {
      set(value) {
        this.panel.options = value;
      },
      get() {
        return this?.panel?.options || null;
      }
    },
    dataList: {
      set(value) {
        if (this?.panel?.options?.data) {
          this.panel.options.data = value;
        } else if (this.target?.data) {
          this.target.data = value;
        }
      },
      get() {
        return this?.panel?.options?.data || this.target?.data || [];
      }
    },
    chartOptions() {
      return (
        this?.panel?.options?.chartOptions || this.target?.chartOptions || null
      );
    },
    dataId: {
      set(vlr) {
        let digits = (vlr + "").match(/\d+/g);
        if (digits) {
          vlr = parseInt(digits.join(""));
        } else {
          vlr = "";
        }
        this.$set(this, "curDataId", vlr || "");
      },
      get() {
        return this.curDataId || "";
      }
    },
    errors() {
      let entry = {
        cellStyle: "" // TODO: implement error parser
      };
      return entry;
    },
    titleText: {
      set(vlr) {
        if (this?.chartOptions?.title) {
          this.$set(this.chartOptions.title, "text", vlr);
          // this.$set(this.chartOptions.grid, "top", vlr ? "20%" : "10%");
          // if (vlr && this?.chartOptions?.legend?.top == "top") {
          //   this.$set(this.chartOptions.legend, "top", "bottom");
          // }
        }
      },
      get() {
        return this?.chartOptions?.title?.text || "";
      }
    },
    titleStyle: titleStyleProperty,
    titlePadding: {
      set(value) {
        this.echartPadding("title", value);
      },
      get() {
        return this.echartPadding("title");
      }
    },
    titlePaddingDisabled() {
      return disabledDimensions((this?.titleStyle || {})["text-align"] || "");
    },
    legendPadding: {
      set(value) {
        this.echartPadding("legend", value);
      },
      get() {
        return this.echartPadding("legend");
      }
    },
    legendPosition: {
      set(value) {
        if (this.chartOptions.legend) {
          let p = value.split("-");
          this.$set(this.chartOptions.legend, "top", p[0]);
          this.$set(this.chartOptions.legend, "left", p[1]);
        }
      },
      get() {
        let p = this?.chartOptions?.legend || { top: "top", left: "left" };
        return `${p.top}-${p.left}`;
      }
    },
    legendPaddingDisabled() {
      // top,right,left,down
      return disabledDimensions(this.legendPosition);
    },
    equipmentDataList() {
      return this.$store.getters["dashboard/extendedDataList"] || [];
    },
    chartSeriesConfig: {
      get() {
        return this.target || this.panelOptions;
      },
      set(val) {
        if (this.target) this.target = val;
        else this.panelOptions = val;
      }
    },
    generalChartOptions: {
      set(value) {
        for (var p in value || {}) {
          this.$set(this.chartOptions, p, value[p]);
        }
      },
      get() {
        return this.chartOptions;
      }
    },
    disconnectionForm: {
      set(value) {
        this.panelOptions = { ...this.panelOptions, ...(value || {}) };
      },
      get() {
        return (this.panel && this.panel.options) || null;
      }
    }
  },
  watch: {
    panelOptions: {
      handler() {
        this.$store.dispatch("dashboard/saveDraftPanel", {
          panel: this.panel,
          screenId: this.draft.screenId
        });
      },
      deep: true
    },
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        this.target = JSON.parse(JSON.stringify(val));
      }
    },
    target: {
      deep: true,
      handler(val) {
        if (!isEqual(val, this.value)) {
          this.$emit("input", val);
        }
      }
    }
  },
  methods: {
    echartPadding(nodeName, value) {
      // echarts padding order convertion setter/getter
      // from echarts: [up,right,down,left]
      //     to input: [top,right,left,down]
      let padding = [0, 0, 0, 0];
      if (value) {
        padding = [value[0] || 0, value[1] || 0, value[3] || 0, value[2] || 0];
        this.$set(this.chartOptions[nodeName], "padding", padding);
      } else {
        padding = (this?.chartOptions || {})[nodeName]?.padding || padding;
        return [
          padding[0] || 0,
          padding[1] || 0,
          padding[3] || 0,
          padding[2] || 0
        ];
      }
    },
    onChartEvent($event) {
      switch ($event.action) {
        case "chart:activate": {
          this.info = $event.details;
          break;
        }
      }
    },
    dataListParser(lst) {
      return (lst || []).filter(
        ({ id, history_enabled, type }) =>
          type !== "string" &&
          (`${id}`.includes("data_group") || history_enabled)
      );
    }
  },
  created() {
    this.$root.$on("chart:event", this.onChartEvent);
  },
  beforeDestroy() {
    this.$root.$off("chart:event", this.onChartEvent);
  }
};
</script>

<style scoped>
label {
  margin-bottom: 0;
}

.tab-content {
  background: white;
}

.inner-panel {
  padding: 10px 0 0 10px;
}

.inner-panel > * {
  margin-bottom: 10px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}

.data-toggle-link {
  padding: 2px;
}

.data-toggle {
  position: absolute;
  right: 2px;
  top: 0;
  padding: 2px;
}

.content-padding {
  padding-top: 5px;
  padding-bottom: 5px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

div.checkbox > div.checkbox > label {
  margin-left: 20px;
}
div.inner-box {
  padding: 10px;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-top: -5px;
}
</style>
