<template>
  <HeaderButton
    class="notifications-menu no-select"
    icon="bell-o"
    :dropdown="true"
    :dropdownTitle="$tc('last_notification', notifications.length)"
    :badgeNumber="unreadNotifications.length"
    :badgeType="badgeType"
    @click="markAsRead()"
    :title="title"
    v-closable="{ handler: menuClosed.bind(this) }"
    v-if="settings.enabled"
    ref="headerButton"
  >
    <template #options>
      <li
        v-for="notification in notifications"
        :key="notification.id"
        :class="['notification', { unread: !notification.read }]"
        data-testid="notification-container"
      >
        <a
          :class="{ 'show-more': notification.showMore }"
          target="_blank"
          :title="
            notification.title
          "
          data-testid="notification"
          @click.stop="notification.showMore = !notification.showMore"
        >
          <!-- level icon -->
          <i
            v-if="getLevelIcon(notification.level)"
            :class="getIconByLevel(notification.level)"
          ></i>
          <!-- Title and "See more" text -->
          <span class="header">
            <span class="title" data-testid="title" :title="notification.title">
              {{ notification.title }}
              <!-- Link (if there is no description) -->
              <Link
                v-if="!notification.description && notification.url"
                :href="notification.url | query(notification.portal_data)"
                
                data-testid="link"
              >
                <i
                  class="glyphicon glyphicon-new-window new-window link"
                  data-testid="new-window"
                  @click="onOpen(notification)"
                ></i>
              </Link>
            </span>
            <span
              class="small see-more"
              v-if="!notification.showMore && notification.description"
            >{{ $t("see_more") }}</span
            >
            
          </span>
          <!-- Description -->
          <p
            v-if="notification.showMore && notification.description"
            style="margin-top: 1em"
            data-testid="description"
          >
            <section v-html="notification.description" class="description"></section>
            <!-- Link -->
            <Link
              v-if="notification.url"
              :href="notification.url | query(notification.portal_data)"
              :title="$t('open')"
              data-testid="link"
            >
              <i
                class="glyphicon glyphicon-new-window new-window link"
                data-testid="new-window"
                @click="onOpen(notification)"
              ></i>
            </Link>
          </p>
        </a>
      </li>
      <li v-if="!notifications.length">
        <a class="text-center text-muted">
          <em>{{ $t("no_notifications") }}</em>
        </a>
      </li>
    </template>
    <template #footer>
      <!-- <a>{{ $t("see_all") }}</a> -->
      <portal to="modal">
        <Modal
          id="notification-modal"
          :content="modalNotification.description_html"
          :open.sync="modalOpen"
          :backdrop="shouldClickToRead() ? 'static' : 'true'"
          @hidden="checkModalQueue"
          @hide="markAsRead(modalNotification)"
        >
          <template #header>
            <span
              :class="[
                'highlight-line',
                {
                  [`bg-${getLevelColor(
                    modalNotification.level
                  )}`]: getLevelColor(modalNotification.level)
                }
              ]"
              data-testid="highlight-line"
            ></span>
            <BaseButton
              type="close"
              @click="closeModal"
              aria-label="Close"
              data-testid="close-x"
              ><span aria-hidden="true">&times;</span></BaseButton
            >
            <h4 class="modal-title" data-testid="title">
              {{ modalNotification.title }}
            </h4>
          </template>
          <template #footer>
            <div class="checkbox">
              <label v-if="shouldClickToRead()">
                <input
                  type="checkbox"
                  v-model="modalAccepted"
                  required
                  ref="accept"
                  data-testid="accept"
                />
                {{ $t("i_know") }}
              </label>
            </div>
            <template v-if="modalNotification.url">
              <BaseButton
                v-if="isExternalLink(modalNotification.url)"
                type="primary"
                tag="a"
                :href="modalNotification.url"
                target="_blank"
                data-testid="open"
                @click="!shouldClickToRead() && markAsRead(modalNotification)"
              >
                {{ $t("open") }}
                <i class="glyphicon glyphicon-new-window new-window"></i>
              </BaseButton>
              <router-link
                v-else
                :to="
                  modalNotification.url | query(modalNotification.portal_data)
                "
                v-slot="{ href, navigate }"
                data-testid="open"
                custom
              >
                <BaseButton
                  type="primary"
                  tag="a"
                  :href="href"
                  @click="
                    closeModal($event, navigate);
                    emitOpen(modalNotification);
                  "
                >
                  {{ $t("open") }}
                </BaseButton>
              </router-link>
            </template>
            <BaseButton @click="closeModal" data-testid="close">
              {{ $t("close") }}
            </BaseButton>
          </template>
        </Modal>
      </portal>
    </template>
  </HeaderButton>
</template>

<script>
import HeaderButton from "./header-button";
import NotificationService from "@/services/notification";
import Modal from "@/components/widgets/modal";
import BaseButton from "@/components/base/buttons/base-button";
import Link from "@/components/base/link";
import closable from "@/directives/closable";

import maxBy from "lodash/maxBy";
export default {
  name: "NotificationsMenu",
  directives: { closable },
  components: { HeaderButton, Modal, BaseButton, Link },
  data() {
    return {
      notifications: [],
      modalOpen: false,
      modalAccepted: false,
      modalNotification: {},
      modalQueue: []
    };
  },
  filters: {
    query(url, portalData) {
      let query = typeof portalData?.query == "object" ? query : null;
      if (query)
        return (
          url +
          (!url.endsWith("/") ? "/" : "") +
          "?" +
          Object.keys(query)
            .reduce((str, key) => (str += key + "=" + query[key] + "&"))
            .slice(0, -1)
        );
      else return url;
    }
  },
  computed: {
    settings() {
      return this.$root.config.system_notifications ?? {};
    },
    popupOptions() {
      return this.$root.config.references?.popup_options;
    },
    readConfirmationOptions() {
      return this.$root.config.references?.read_confirmation_options || {};
    },
    unreadNotifications() {
      return this.notifications.filter((n) => n.read == false);
    },
    badgeType() {
      let maxLevel = maxBy(this.unreadNotifications, "level")?.level ?? 1;
      let color = this.settings?.levels?.[maxLevel]?.color || "primary";
      return `bg-${color}`;
    },
    title() {
      return this.unreadNotifications.length
        ? this.$tc(
          "you_have_unread_notifications",
          this.unreadNotifications.length,
          {
            count: this.unreadNotifications.length
          }
        )
        : this.$tc("notification", 2);
    },
    settingsLoaded() {
      return this.$store.getters["user/isSettingsLoaded"];
    }
  },
  watch: {
    settingsLoaded(val) {
      if (val) this.checkNotificationsPopups();
    }
  },
  methods: {
    shouldClickToRead() {
      const readConfirmationOptions = this.$root.config.references?.read_confirmation_options || {}
      return (
        this.modalNotification.read_confirmation ==
        readConfirmationOptions?.click
      );
    },
    getIconByLevel(level) {
      let icon = this.getLevelIcon(level);
      let color = this.getLevelColor(level)
        ? `text-${this.getLevelColor(level)}`
        : "";
      return `fa fa-${icon} ${color}`;
    },
    getLevelIcon(level) {
      return (
        this.settings.levels[level].icon ?? this.settings.defaultIcon ?? ""
      );
    },
    getLevelColor(level) {
      return this.settings.levels[level]?.color;
    },
    async fetchNotifications() {
      try {
        const contract_id = this.$store.getters["user/contract_id"];
        return await this.service.fetch({ contract_id });
      } catch (e) {
        return [];
      }
    },
    async markAsRead(notification) {
      const readConfirmationOptions = this.$root.config.references?.read_confirmation_options || {}
      const ids = [];
      if (notification && !notification.read) {
        notification.read = true;
        ids.push(notification.id);
      } else {
        this.notifications.forEach((notification) => {
          if (
            notification.read_confirmation ==
            readConfirmationOptions.open &&
            !notification.read
          ) {
            notification.read = true;
            ids.push(notification.id);
          }
        });
      }
      if (ids.length) {
        try {
          await this.service.setRead(ids);
        } catch (e) {
          console.error(e.message);
        }
      }
    },
    showPopup(notification) {
      const popupType =
        notification.popup != this.popupOptions.no_popup
          ? notification.popup
          : this.settings.levels[notification.level].popup;
      const popupMethod = this.getPopupMethodFor(popupType);
      this[popupMethod](notification);
    },
    shouldPopup(notification) {
      return (
        (notification.popup != this.popupOptions.no_popup ||
          this.settings.levels[notification.level].popup !=
          this.popupOptions.no_popup) &&
        !notification.read
      );
    },
    getPopupMethodFor(type) {
      let typeName = Object.keys(this.popupOptions).find(
        (key) => this.popupOptions[key] == type
      );
      let methodName = `show${typeName[0].toUpperCase() +
        typeName.substring(1)}`;
      return methodName;
    },
    showToasted(notification) {
      // disregard this notification from unread
      // counter displayed on badge while is being poped
      notification.read = true;
      const readConfirmationOptions = this.$root.config.references?.read_confirmation_options || {}
      const color = this.getLevelColor(notification.level);
      const action = [];
      if (notification.url) {
        action.push({
          text: this.$t("see"),
          class: "toasted-custom-action",
          onClick: (e, toastObject) => {
            this.emitOpen(notification);
            if (this.isExternalLink(notification.url)) {
              window.open(notification.url, "_blank");
            } else if (this.$route.path != notification.url) {
              this.$router.push(notification.url).catch(() => { });
            }
            toastObject.goAway(0);
            // mark as read when the confirmation
            // type is either open or click
            notification.read = false;
            this.markAsRead(notification);
          }
        });
      }
      action.push({
        text: this.$t("close"),
        class: "toasted-custom-action",
        onClick: (e, toastObject) => {
          toastObject.goAway(0);
          notification.read = false;
          // if it just needs to be open, mark it as read
          if (
            notification.read_confirmation == readConfirmationOptions.open
          ) {
            this.markAsRead(notification);
          }
        }
      });
      this.$toasted.show(notification.title, {
        theme: "toasted-primary",
        position: "top-right",
        duration: 5000,
        keepOnHover: true,
        type: color ? `bg-${color}` : "",
        icon: this.getLevelIcon(notification.level),
        iconPack: "fontawesome",
        action,
        onComplete: () => (notification.read = false)
      });
    },
    showModal(notification) {
      // add notification to modal queue
      // and open it if possible
      this.modalQueue.push(notification);
      this.checkModalQueue();
    },
    closeModal(e, navigate) {
      // validates acknowledgment based on read confirmation type
      if (this.shouldClickToRead() && !this.$refs.accept.reportValidity()) {
        e.preventDefault();
        return;
      }
      // navigate to url if needed
      if (navigate) navigate(e);
      // closes modal
      this.modalOpen = false;
    },
    checkModalQueue() {
      // if there is a modal open, return
      if (this.modalOpen) return;
      // updates modal with props from next
      // notification on queue and opens it again
      if (this.modalQueue.length) {
        this.modalNotification = this.modalQueue.shift();
        this.modalAccepted = false;
        this.modalOpen = true;
      }
    },
    checkNotificationsPopups() {
      this.notifications.forEach(
        (notification) =>
          this.shouldPopup(notification) && this.showPopup(notification)
      );
    },
    isExternalLink(link) {
      return link.match(/http(s)?/) ? true : false;
    },
    closeMenu() {
      this.$refs.headerButton.$el.click();
    },
    menuClosed() {
      this.notifications.forEach((notification) => {
        notification.showMore = false;
      });
    },
    onOpen(notification) {
      this.closeMenu();
      this.markAsRead(notification);
      this.emitOpen(notification);
    },
    emitOpen(notification) {
      this.$root.$emit("notification:open", notification);
    }
  },
  beforeCreate() {
    this.service = new NotificationService();
  },
  beforeDestroy() {
    this.service = null;
  },
  mounted() {
    if (this.settings.enabled) {
      this.fetchNotifications().then((response) => {
        // for testing purpose
        // response = [
        //   {
        //     id: this.$utils.uuid(),
        //     showMore: true,
        //     read: false,
        //     title: "Excedido limite de Notificaçoes de Desconexão",
        //     description: "Excedido limite de Notificaçoes de Desconexão Excedido limite de Notificaçoes de Desconexão Excedido limite de Notificaçoes de Desconexão",
        //     level: 1,
        //     url: "http://hi-dev.uhit.me",
        //   }
        // ]
        this.notifications = response.map((n) => {
          n.showMore = false;
          return n;
        });
        if (this.settingsLoaded) {
          this.checkNotificationsPopups();
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.notifications-menu::v-deep > .dropdown-menu {
  width: 400px;
}

@media (max-width: 640px) {
  .notifications-menu::v-deep > .dropdown-menu {
    right: 0;
    width: 100%;
  }
}
.new-window {
  position: relative;
  font-size: 0.65em;
  margin-left: 0.2em;
  top: -0.2em;
  left: -0.1em;

  &.link {
    font-size: 0.85em;
    margin-left: 0.3em;
    top: 0.1em;
    color: #444;

    &:hover {
      color: #337ab7;
    }
  }
}

.notification {
  &.unread {
    font-weight: 600;
  }

  a.show-more {
    white-space: initial !important;
  }

  .header {
    display: inline-flex;
    width: calc(100% - 20px);

    .title {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .see-more {
      color: #337ab7;
      text-decoration: underline;
      font-size: 0.9em;
      flex: 1;
      text-align: right;
    }
  }
  p {
    section.description {
      font-weight: normal;
    }
  }
}
</style>
<style lang="scss">
.notifications-menu::v-deep > .dropdown-menu {
  width: 400px;
}

@media (max-width: 640px) {
  .notifications-menu::v-deep > .dropdown-menu {
    right: 0;
    width: 100%;
  }
}

.toasted-container .toasted {
  &.bg-primary {
    background: #337ab7;
  }

  .action.toasted-custom-action {
    font-size: 1.4rem !important;
    color: white;
  }
}

#notification-modal {
  .highlight-line {
    width: 100%;
    height: 4px;
    display: block;
    position: absolute;
    top: -4px;
    left: 0;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  }

  .modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .checkbox {
      display: inline-block;
      flex: 1;
      text-align: left;
      font-weight: 600;

      label {
        margin: 0;
      }
    }
  }
}
</style>
