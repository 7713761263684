<template>
  <a
    v-if="isExternalLink(href)"
    target="_blank"
    v-bind="$attrs"
    v-on="$listeners"
    :href="href"
  >
    <slot></slot>
  </a>
  <router-link v-else v-bind="$attrs" v-on="$listeners" :to="href">
    <slot></slot>
  </router-link>
</template>

<script>
export default {
  name: "Link",
  inheritAttrs: false,
  props: {
    href: {
      type: String,
      default: ""
    }
  },
  methods: {
    isExternalLink(link) {
      return link?.match?.(/http(s)?/) ? true : false;
    }
  }
};
</script>

<style></style>
