<template>
  <section class="me">
    <div class="align-container">
      <div v-for="(row, i) in alignItems" :key="i">
        <div
          class="btn btn-xs"
          v-for="(col, j) in row"
          :class="col.name == value ? 'btn-active' : ''"
          :key="j"
          @click.stop.prevent="align = col.name"
          :title="title(col.name)"
        >
          <i :class="col.icon" :style="col.style"></i>
        </div>
      </div>
      <div v-if="showSelected">
        {{ title(value) }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "AlignSelector",
  props: {
    value: {
      type: String,
      required: false,
      default: () => "top-left"
    },
    showSelected: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    align: {
      set(vlr) {
        this.$emit("input", vlr);
      },
      get() {
        return this.value;
      }
    }
  },
  data() {
    return {
      alignItems: [
        [
          {
            name: "top-left",
            icon: "fa fa-chevron-left",
            style: { transform: "rotate(45deg)" }
          },
          { name: "top-center", icon: "fa fa-chevron-up", style: {} },
          {
            name: "top-right",
            icon: "fa fa-chevron-right",
            style: { transform: "rotate(-45deg)" }
          }
        ],
        [
          { name: "middle-left", icon: "fa fa-chevron-left", style: {} },
          { name: "middle-center", icon: "fa fa-arrows", style: {} },
          { name: "middle-right", icon: "fa fa-chevron-right", style: {} }
        ],
        [
          {
            name: "bottom-left",
            icon: "fa fa-chevron-left",
            style: { transform: "rotate(-45deg)" }
          },
          { name: "bottom-center", icon: "fa fa-chevron-down", style: {} },
          {
            name: "bottom-right",
            icon: "fa fa-chevron-right",
            style: { transform: "rotate(45deg)" }
          }
        ]
      ]
    };
  },
  methods: {
    title(name) {
      return this.$t(name.replace(/\-/, "_"));
    }
  }
};
</script>

<style scoped>
.me {
  text-align: center;
  width: inherit;
  white-space: nowrap;
  margin: 0 auto;
}

.align-container > div {
  margin: -2px;
}

.btn-active {
  color: #ff5722;
}
</style>
