<template>
  <DetailForm persistent="toggle_image_panel">
    <DetailFormImage />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormImage from "@/components/control-sidebar/property-editors/detail-form-image.vue";
export default {
  name: "ImageForm",
  components: {
    DetailForm,
    DetailFormImage
  }
};
</script>
