<template>
  <DetailForm persistent="toggle_chart_panel">
    <DetailFormChart />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormChart from "@/components/control-sidebar/property-editors/detail-form-chart.vue";

export default {
  name: "ChartForm",
  components: {
    DetailForm,
    DetailFormChart
  }
};
</script>
