<template>
  <div
    style="clear: both"
    v-if="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    ref="modalDialog"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-title text-left">
            {{ title ? $tc(title, 2) : data.name }}
            <span class="badge">
              {{ length }}
            </span>
          </div>
        </div>
        <div class="modal-body">
          <EquipmentDataEditorForm
            class="form"
            :controls="controls"
            v-model="formData"
          />
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-default pull-left"
            data-dismiss="modal"
            aria-label="Close"
          >
            {{ $t("cancel") }}
          </button>
          <button
            class="btn btn-primary pull-right"
            :disabled="!canSave"
            @click.stop.prevent="save"
          >
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <EquipmentDataEditorForm :controls="controls" v-model="formData" />
  </div>
</template>

<script>
import EquipmentDataEditorForm from "@/components/equipment-data-editor-form.vue";
import { currentValueTypeCast } from "@/services/equipment-data.js";
import EquipmentDataService from "@/services/equipment-data.js";
export default {
  name: "FormDataArray",
  components: {
    EquipmentDataEditorForm
  },
  props: {
    modal: {
      type: Boolean,
      required: false,
      default: false
    },
    title: {
      type: String,
      required: false,
      default: ""
    },
    data: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data: () => ({
    formData: null
  }),
  computed: {
    saveStatus() {
      return this.$store.getters["command/saveStatus"];
    },
    canSave() {
      return this.formData != null && this.saveStatus != "busy";
    },
    length() {
      return this?.data?.memory_size ?? 1;
    },
    controls() {
      let lst = [];
      let data = { ...this.data };
      data.type = this.srv.basicType(data)?.type || "float"; // default
      for (var i = 0; i < this.data.memory_size; i++) {
        var item = { ...this.data };
        item.current_value = {
          value: this.$root.$formatter.rawValue(data, i)
        };
        currentValueTypeCast(item);
        item.memory_size = 1;
        item.portal_data = item.portal_data || {};
        item.portal_data.data_value_index = null;
        lst.push({
          equipment_id: item.device.connector_id,
          data_id: item.id,
          value: item.current_value.value,
          unit: item.unity_label || "",
          type: data.type,
          dataType: item.memory_type.name || "",
          readOnly: item.read_only,
          name: `${this.$t("titles.value_at")} [${i}]`,
          format: "",
          decimal: "",
          required: null,
          default: "",
          min: item.minimum_value !== "" ? item.minimum_value : null,
          max: item.maximum_value !== "" ? item.maximum_value : null,
          data: item
        });
      }
      return lst;
    }
  },
  methods: {
    save() {
      if (!this.canSave || !(this?.formData || []).length) return;
      let payload = (this.formData || []).map((control, ix) => ({
        data_id: this.data.id,
        value: control.value,
        data: {
          id: this.data.id,
          memory_size: this.data.memory_size,
          type: this.data.type,
          current_value:
            (this.data?.current_value?.value && {
              value: this.data?.current_value?.value ?? null
            }) ??
            null,
          portal_data: {
            data_value_index: {
              data_id: "",
              type: "constant",
              enabled: true,
              value: ix,
              offset: 0
            }
          }
        }
      }));
      this.$emit("save", payload);
    },
    close() {
      $(this.$refs.modalDialog).modal("hide");
    },
    open() {
      this.command = null;
      $(this.$refs.modalDialog).modal("show");
    }
  },
  mounted() {
    if (this.modal) {
      let self = this;
      $(this.$refs.modalDialog)
        .on("shown.bs.modal", () => {
          self.$root.$emit("editor.keyboard:stop");
          self.$emit("open");
        })
        .on("hidden.bs.modal", () => {
          self.$root.$emit("editor.keyboard:start");
          self.$emit("close");
        });
      this.open();
    }
  },
  beforeCreate() {
    this.srv = new EquipmentDataService();
  },
  beforeDestroy() {
    this.srv = null;
  }
};
</script>

<style scoped>
.modal-content {
  border-radius: 5px;
}
.modal-title {
  font-size: 14pt;
  line-height: initial;
}
.modal-body {
  max-height: 70dvh;
  overflow: auto;
}
.modal-body > .form {
  background-color: whitesmoke;
  border-radius: 10px;
  outline: 1px solid #dadada;
  margin: -12px -10px -25px -10px;
}
.badge {
  margin: -15px 0 0 -1px;
  background-color: #777;
}
</style>