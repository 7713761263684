<template>
  <section class="content">
    <div class="alert alert-warning">
      <button
        type="button"
        class="close"
        data-dismiss="alert"
        aria-hidden="true"
      >
        ×
      </button>
      <h4><i class="icon fa fa-warning"></i> {{ $t("invalid_component") }}</h4>
      <p>
        {{ $t("vue_component") }}: <b>{{ title }}</b> -
        {{ $t("component_not_found") }}
      </p>
      <p v-if="isMissing">
        <small>* {{ $t("please_assign_the_title_of_the_property") }}</small>
      </p>
    </div>
    <!-- /.box -->
  </section>
</template>

<script>
export default {
  name: "ComponentNotAvailable",
  props: {
    title: {
      type: String,
      default: "Unknown"
    }
  },
  computed: {
    isMissing: function() {
      return this.title == "Unknown";
    }
  }
};
</script>

<style scoped></style>
