<template>
  <DetailForm persistent="toggle_connector_data_list_panel">
    <DetailFormData />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormData from "@/components/control-sidebar/property-editors/detail-form-data.vue";

export default {
  name: "ConnectorDataListForm",
  components: {
    DetailForm,
    DetailFormData
  }
};
</script>
