<template>
  <DetailForm persistent="toggle_image_panel">
    <DetailFormRichText />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormRichText from "@/components/control-sidebar/property-editors/detail-form-rich-text.vue";
export default {
  name: "RichTextForm",
  components: {
    DetailForm,
    DetailFormRichText
  }
};
</script>
