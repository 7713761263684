<template>
  <div>
    <div class="modal fade" tabindex="-1" role="dialog" ref="dlgImport">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 class="modal-title">{{ $t("screen_importation") }}</h4>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label for="">{{ $t("destination") }}</label>
              <div class="input-group">
                <div class="input-group-addon">
                  {{ $tc("screen") }}
                </div>
                <select class="form-control" v-model="screen_id">
                  <option value="">{{ $tc("new", 2) }}</option>
                  <option
                    :value="screen.id"
                    :key="screen.id"
                    v-for="screen in parsedScreens"
                  >
                    {{ screen.name }}
                  </option>
                </select>
                <div class="input-group-btn">
                  <button
                    type="button"
                    class="btn dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    V{{ version }} <span class="caret"></span>
                  </button>
                  <ul class="dropdown-menu" style="">
                    <li>
                      <a href="#" @click="version = 0">V0</a>
                      <a href="#" @click="version = 1">V1</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="alert alert-danger" v-if="screen_id">
              {{ $t("this_will_reset_all_of_your_unpushed_changes") }}
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              v-on:click.stop.prevent="onSave"
            >
              {{ $t("import") }}
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->
  </div>
</template>

<script>
import { nextId } from "@/services/dashboard";
import Panels from "@/assets/dashboard/panels.json";
export default {
  name: "ImportForm",
  props: {
    screenId: {
      type: [String, Number],
      default: "",
      required: false
    }
  },
  data() {
    return {
      fileSelected: false,
      template: null,
      screen_id: "",
      version: 0
    };
  },
  computed: {
    screens() {
      return this.$store.getters["dashboard/screens"] || [];
    },
    parsedScreens() {
      let lst = JSON.parse(JSON.stringify(this.screens));
      // lst.push({ id: "", name: this.$tc("new", 2), public: false });
      return lst
        .filter((i) => {
          return !i.public;
        })
        .map((i) => {
          return {
            id: i.id,
            name: i.id ? i.name + " [#" + i.id + "]" : i.name
          };
        })
        .sort((a, b) => {
          if (a.name > b.name) return 1;
          if (b.name > a.name) return -1;
          return 0;
        });
    },
    draft() {
      return this.$store.getters["dashboard/draft"] || null;
    },
    draftScreenId() {
      return this?.draft?.screenId || "";
    }
  },
  watch: {
    // screenId: {
    //   handler(n) {
    //     this.screen_id = n;
    //   },
    //   immediate: true
    // }
  },
  methods: {
    onSave() {
      if (this.template) {
        this.upgrade();
        if (this.screen_id) {
          let screen = JSON.parse(
            JSON.stringify(this.screens.find((i) => i.id == this.screen_id))
          );
          if (screen) {
            this.$store.commit("dashboard/SAVE_DRAFT", {
              screenId: this.screen_id,
              template: this.template
            });
            this.$store.dispatch("dashboard/initDraft", this.screen_id);
          }
          this.close();
        } else {
          this.$store.dispatch("dashboard/create", this.template).then((draft) => {
            this.$store.dispatch("dashboard/setParent", {
              dbKey: 'tree_screen',
              id: draft.screenId
            });
            this.close();
          });
        }
      }
    },
    close() {
      if (this.fileSelected) {
        $(this.$refs.dlgImport).modal("hide");
      } else {
        this.$emit("close");
      }
    },
    upgrade() {
      // convert to the new render version (if needed)
      if (this.version && this.template && !this.template.render_version) {
        this.template.render_version = this.version;
        this.template.panels.forEach((panel) => {
          let tpl =
            Panels.find((i) => i.template.template == panel.template) || null;
          if (tpl) {
            panel.heightProportion = tpl.template.heightProportion;
            panel.style = JSON.parse(JSON.stringify(tpl.template.style));
          }
        });
      }
    },
    readFile(file) {
      let self = this;
      if (!file || ["text/plain", "application/json"].indexOf(file.type) < 0) {
        this.close();
        return;
      }
      let reader = new FileReader();
      reader.onload = (evt) => {
        try {
          self.template = JSON.parse(evt.target.result || "");
          delete self.template.origin;
          delete self.template.draft;
          self.version = self.template.render_version ? 1 : 0;
          $(this.$refs.dlgImport).modal("show");
        } catch (e) {
          this.close();
        }
      };
      reader.onerror = () => {
        this.close();
      };
      reader.readAsText(file, "UTF-8");
    },
    onUpload() {
      let self = this;
      return new Promise((resolve, reject) => {
        // create input file
        const el = document.createElement("input");
        el.id = +new Date();
        el.style.display = "none";
        el.setAttribute("type", "file");
        el.addEventListener(
          "change",
          () => {
            self.fileSelected = true;
            if (!el?.files?.length) {
              reject();
            } else {
              resolve(el.files[0]);
            }
            document.body.removeChild(document.getElementById(el.id));
          },
          { once: true }
        );
        window.addEventListener(
          "focus",
          () => {
            setTimeout(() => {
              if (!self.fileSelected && document.getElementById(el.id)) {
                reject();
                document.body.removeChild(document.getElementById(el.id));
              }
            }, 300);
          },
          { once: true }
        );
        document.body.appendChild(el);
        el.click();
      });
    }
  },
  mounted() {
    let nextScreenId = nextId();
    try {
      this.onUpload().then(
        (file) => {
          this.readFile(file);
        },
        () => {
          this.close();
        }
      );
    } catch (e) {
      this.close();
    }
    $(this.$refs.dlgImport).on("hide.bs.modal", () => {
      this.$emit(
        "close",
        nextScreenId != nextId() ? nextScreenId : this.screen_id
      );
    });
  }
};
</script>

<style scoped>
.input-group-btn > .dropdown-toggle {
  background-color: transparent;
  display: block;
  border-color: lightgray;
}

.input-group-btn > .dropdown-toggle:focus {
  outline-color: transparent;
}
.input-group-btn > .dropdown-menu {
  min-width: 0;
  margin-left: -8px;
}
</style>
