<template>
  <HeaderButton
    class="notifications-menu no-select"
    icon="book"
    :dropdown="true"
    :dropdownTitle="$t('terms_of_service_update_menu_title')"
    :badgeNumber="1"
    badgeType="bg-primary"
    :title="$t('terms_of_service_update_menu_title')"
    v-if="pendingTermsOfService"
    ref="headerButton"
  >
    <template #options>
      <li>
        <p class="description">
          {{ $t("terms_of_service_update_message") }}
        </p>
      </li>
    </template>
    <template #footer>
      <Link href="/terms_of_service">
        <i class="glyphicon glyphicon-new-window new-window link"></i>
        {{ $t("click_to_read") }}
      </Link>
    </template>
  </HeaderButton>
</template>

<script>
import HeaderButton from "./header-button";
import TermsOfServiceService from "@/services/terms-of-service.js";
import Link from "@/components/base/link";
import closable from "@/directives/closable";

export default {
  name: "TermsOfServiceButton",
  directives: { closable },
  components: { HeaderButton, Link },
  data() {
    return {
      ready: false
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/loggedUser"] || null;
    },
    contract() {
      return this.$store.getters["user/contract"] || null;
    },
    termsAcceptanceDatetime() {
      return this.$store.getters["user/termsAcceptanceDatetime"] || null;
    },
    pendingTermsOfService() {
      return this.ready && this.user.is_root && !this.termsAcceptanceDatetime;
    }
  },
  methods: {
    fetchTermsOfService() {
      if (!this.contract?.id) return;
      var svr = new TermsOfServiceService();
      svr.get({ contract_id: this.contract.id }).then((ret) => {
        if (ret)
          this.$store.dispatch("user/setTermsAcceptanceDatetime", ret?.accepted_datetime ?? null);
        this.ready = true;
      });
      svr = null;
    }
  },
  watch: {
    contract: {
      handler(n, o) {
        if (!n || n == o) return;
        if (this.termsAcceptanceDatetime) this.ready = true;
        else this.fetchTermsOfService();
      },
      immediate: true
    }
  }
};
</script>

<style scoped>
.description {
  word-wrap: initial;
  text-wrap: initial;
  margin-top: 1em;
  margin: 10px;
}
</style>
