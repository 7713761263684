<template>
  <section>
    <div class="h4 text-center" style="padding-top: 60px" v-if="isEmpty">
      {{ $t("no_data_found") }}
    </div>
    <div class="list-group" v-else>
      <div class="list-group-item" v-for="item in dataList" :key="item.id">
        <div class="item-header" :title="itemTitle(item)">
          {{ item.name }}
          <span
            class="pull-right btn btn-xs"
            v-if="item.memory_size > 1 && expandedArrays[item.id]"
            @click.stop.prevent="toggleArrayEditor(item)"
            :title="$t('close')"
            ><i class="fa fa-close"></i
          ></span>
        </div>
        <TestDataValue
          v-if="!expandedArrays[item.id]"
          :dataId="item.id"
          :arrayEditor="false"
          :dataValueIndex="dataValueIndex(item)"
          style="width: 100%"
          @editArray="toggleArrayEditor"
        />
        <div
          class="box box-default"
          v-if="item.memory_size > 1 && expandedArrays[item.id]"
        >
          <div class="box-body">
            <FormDataArray
              :data="item"
              @save="onSaveDataArray(item.id, $event)"
              :ref="`formDataArray${item.id}`"
            />
            <div>
              <span
                class="btn btn-xs btn-default pull-left"
                @click.stop.prevent="toggleArrayEditor(item)"
              >
                {{ $t("cancel") }}
              </span>
              <span
                class="btn btn-xs btn-primary pull-right"
                @click.stop.prevent="saveArrayEditor(item)"
              >
                {{ $t("save") }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {initialValue as DftDataValueIndex} from "@/components/editor/value-source-selector.vue";
import TestDataValue from "@/components/synoptic/property-editor/controls/test-data-value.vue";
import FormDataArray from "@/components/registration/form-data-array.vue";
export default {
  name: "DashboardDataValueSimulationForm",
  components: {
    TestDataValue,
    FormDataArray
  },
  data() {
    return {
      busy: false,
      expandedArrays: {}
    };
  },
  computed: {
    draft() {
      return this.$store.getters["dashboard/draft"];
    },
    templateDataIdList() {
      const _getDataIds = (template, recursive = false) => {
        let dataIds = {};
        for (let prop in template) {
          if (typeof template[prop] == "object") {
            dataIds = {
              ...dataIds,
              ..._getDataIds(template[prop], true)
            };
          } else if (prop == "data_id" && template[prop]) {
            dataIds[template[prop]] = true;
          }
        }
        if (!recursive) dataIds = Object.keys(dataIds);
        return dataIds;
      };
      return (_getDataIds(this.draft.template) || []).map((i) => parseInt(i));
    },
    dataList() {
      return this.$store.getters["dashboard/dataList"]
        .filter(({id}) => (this.templateDataIdList || []).indexOf(id) >= 0)
        .sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    isEmpty() {
      return !this.dataList.length;
    }
  },
  methods: {
    itemTitle(item) {
      return `data: ${item.id} - ${item.name}\ndevice: ${
        item?.device?.id || "?"
      } - ${item?.device?.name || "?"}\nconnector: ${
        item?.device?.connector?.id || "?"
      } - ${item?.device?.connector?.name || "?"}`;
    },
    dataValueIndex(item) {
      if (!(item.memory_size > 1)) return null;
      let entry = item?.data_value_index || DftDataValueIndex();
      if (entry.type == "constant" && !(entry.value >= 0)) {
        entry.value = 0;
      }
      return entry;
    },
    toggleArrayEditor(item) {
      if (!item || item.memory_size <= 1) return;
      this.$set(
        this.expandedArrays,
        item.id,
        !(this.expandedArrays[item.id] ?? false)
      );
    },
    saveArrayEditor(item) {
      if (!item || item.memory_size <= 1) return;
      if (this.expandedArrays[item.id]) {
        if ((this.$refs[`formDataArray${item.id}`] || []).length) {
          this.$refs[`formDataArray${item.id}`][0].save();
        }
      }
    },
    onSaveDataArray(dataId, fields) {
      let value = (fields || []).map(({value}) => value).join(",");
      this.$store.dispatch("dashboard/setDataValue", {
        id: dataId,
        value: value
      });
    }
  },
  beforeCreate() {
    this.$store.dispatch("dashboard/simulation", true);
  },
  beforeDestroy() {
    this.$store.dispatch("dashboard/simulation", false);
  }
};
</script>

<style scoped>
section {
  width: 100%;
}
.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}

.box-header {
  padding: 0 5px;
}
</style>