<template>
  <DetailForm persistent="toggle_custom_panel">
    <DetailFormCustomPanel />
  </DetailForm>
</template>

<script>
import DetailForm from "@/components/control-sidebar/property-editors/detail-form.vue";
import DetailFormCustomPanel from "@/components/control-sidebar/property-editors/detail-form-custom-panel.vue";
export default {
  name: "CustomPanelForm",
  components: {
    DetailForm,
    DetailFormCustomPanel
  }
};
</script>
